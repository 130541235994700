import axios from 'axios'
import { merge } from 'lodash'
import { camelizeKeys } from 'humps'
import appConfig from './appConfig'

const serializeParams = (params) => {
  const encode = (val) => {
    return encodeURIComponent(val)
      .replace(/%3A/gi, ':')
      .replace(/%24/g, '$')
      .replace(/%2C/gi, ',')
      .replace(/%20/g, '+')
      .replace(/%5B/gi, '[')
      .replace(/%5D/gi, ']')
  }

  const parts = []

  Object.entries(params).forEach(([key, val]) => {
    if (val === null || typeof val === 'undefined') {
      return
    }

    if (Array.isArray(val)) {
      key = key + '[]'
    } else {
      val = [val]
    }

    val.forEach((v) => {
      if (Object.prototype.toString.call(v) === '[object Date]') {
        v = v.toISOString()
      } else if (typeof v === 'object') {
        v = JSON.stringify(v)
      }
      parts.push(encode(key) + '=' + encode(v))
    })
  })

  return parts.join('&')
}
const interceptRequests = () => {
  axios.defaults.baseURL = appConfig.API_URL
  axios.defaults.paramsSerializer = {
    serialize: serializeParams,
  }
  axios.interceptors.request.use(
    (config) =>
      merge({}, config, {
        headers: merge({}, config.headers, {
          // Authorization: `Bearer ${selectToken(state, 'Auth')}`,
        }),
      }),

    (error) => {
      console.error('API Request Error: --------------------- v')
      return Promise.reject(error)
    },
  )
}

const interceptResponses = () => {
  axios.interceptors.response.use((response) => {
    return response.data instanceof Blob ? response.data : camelizeKeys(response.data)
  })
}

const enableAxiosInterceptors = () => {
  interceptRequests()
  interceptResponses()
}

export default enableAxiosInterceptors
