import React from 'react'
import { Button, Flex } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import sizes from '../../../ui-kit/sizes'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import align from '../../../ui-kit/align'
import MenuDropdown from '../../../ui-kit/components/menuDropdown/MenuDropdown'
import { checkOnUniqueElement } from '../../../utils/utils'
import { paymentTransactionStatuses } from '../../payments/paymentsTab/util'
import Tooltip from '../../../ui-kit/components/tooltip/Tooltip'
import ValidationTooltips from './ValidationTooltips'
import { penaltyStatuses } from '../invoicesUtils'
import { paymentPromiseStatuses } from '../../payments/promiseToPay/util'
import { PAYMENT_PLAN_STATUSES } from '../../../constants/activities'

export const isProcessingStatus = (paymentTransactionStatus) =>
  paymentTransactionStatus === paymentTransactionStatuses.PROCESSING ||
  paymentTransactionStatus === paymentTransactionStatuses.ENQUEUED ||
  paymentTransactionStatus === paymentTransactionStatuses.SUBMITTED

const invoiceValidationChecks = {
  sameContract: (models = []) =>
    checkOnUniqueElement(models.map((model) => model?.contract?.id)) &&
    'invoicesFromDifferentContract',
  payment: (models = []) =>
    models.findIndex(
      (model) =>
        model?.latestPayment?.paymentTransaction &&
        isProcessingStatus(model?.latestPayment?.paymentTransaction?.status),
    ) > -1 && 'invoicePaid',
  paymentPromise: (models = []) =>
    models.findIndex(
      (model) =>
        model?.paymentPromise && model?.paymentPromise?.status === paymentPromiseStatuses.ACTIVE,
    ) > -1 && 'invoicePromisedToPay',
  paymentPlan: (models = []) =>
    models.findIndex(
      (model) =>
        model?.latestPaymentPlan &&
        model?.latestPaymentPlan?.status !== PAYMENT_PLAN_STATUSES.CANCELLED,
    ) > -1 && 'invoicePlannedToPay',
  penaltyStatus: (models) =>
    models.some((model) => model.penaltyStatus !== penaltyStatuses.OPEN) && 'why',
}

export const invoiceActionsResolver = {
  receivePayment: ['sameContract', 'payment', 'paymentPlan'],
  promiseToPay: ['sameContract', 'payment', 'paymentPromise', 'paymentPlan'],
  paymentPlan: ['sameContract', 'paymentPlan', 'payment'],
  requestPayment: ['sameContract', 'payment', 'paymentPlan'],
  penaltyStatus: ['penaltyStatus'],
}

export const validateInvoice = (key, models) => {
  let validError
  if (invoiceActionsResolver[key]) {
    invoiceActionsResolver[key].some((checkKey) => {
      validError = invoiceValidationChecks[checkKey] && invoiceValidationChecks[checkKey](models)
      if (validError) {
        return validError
      }
    })
  }
  return validError
}

export const getValidTooltipContent = (key, models) => {
  const validError = validateInvoice(key, models)

  if (validError) {
    return <ValidationTooltips actionKey={key} errorKey={validError} />
  }
}

export const getMoreDropdownOptions = (
  {
    onDownloadAction,
    onP2PAction,
    onPaymentPlanAction,
    onRequestPaymentAction,
    onMarkAsDispute,
    onMarkAsRetention,
    onUnmarkAsRetention,
    onMarkAsResolved,
    onRefund,
    onAssignToProject,
  },
  models,
  t,
) =>
  [
    onP2PAction && {
      key: 'promiseToPay',
      label: t('addP2P'),
      onClick: () => onP2PAction(),
    },
    onPaymentPlanAction && {
      key: 'paymentPlan',
      label: t('paymentPlan'),
      onClick: () => onPaymentPlanAction(),
    },
    onRequestPaymentAction && {
      key: 'requestPayment',
      label: t('requestPayment'),
      onClick: () => onRequestPaymentAction(),
    },
    onMarkAsDispute && {
      key: 'markAsDispute',
      label: t('markAsDisputed'),
      onClick: () => onMarkAsDispute(),
      testData: 'markAsDisputed',
    },
    onMarkAsResolved && {
      key: 'markAsResolved',
      label: t('markAsResolved'),
      onClick: () => onMarkAsResolved(),
      testData: 'markAsResolved',
    },
    onMarkAsRetention && {
      key: 'markAsRetention',
      label: t('markAsRetention'),
      onClick: () => onMarkAsRetention(),
    },
    onUnmarkAsRetention && {
      key: 'unmarkAsRetention',
      label: t('unmarkAsRetention'),
      onClick: () => onUnmarkAsRetention(),
    },
    onAssignToProject && {
      key: 'assignToProject',
      label: t('assignToProject'),
      onClick: () => onAssignToProject(),
    },
    onRefund && {
      key: 'issueRefund',
      label: t('issueRefund'),
      onClick: () => onRefund(),
    },
    onDownloadAction && {
      key: 'download',
      label: t('download'),
      onClick: () => onDownloadAction(),
      testData: 'download',
    },
  ]
    .filter(Boolean)
    .map((action) => {
      return {
        ...action,
        validationError: validateInvoice(action.key, models),
        toolTipContent: getValidTooltipContent(action.key, models),
      }
    })

const InvoiceActions = ({
  alignment,
  onDownloadAction,
  models,
  onReceivePaymentAction,
  onP2PAction,
  onPaymentPlanAction,
  onRequestPaymentAction,
  onMarkAsDispute,
  onMarkAsResolved,
  onRefund,
  onAssignToProject,
}) => {
  const { t } = useTranslation()

  return (
    <Flex>
      <Flex>
        <Tooltip content={getValidTooltipContent('receivePayment', models)}>
          <Button
            className="px-6 mr-2 w-40"
            disabled={!!validateInvoice('receivePayment', models)}
            label={t('receivePayment')}
            onClick={onReceivePaymentAction}
            size={sizes.SM}
            testData="receive-payment-action"
            variant={buttonsVariants.PRIMARY}
          />
        </Tooltip>
      </Flex>

      <MenuDropdown
        alignment={alignment}
        className="w-full"
        options={getMoreDropdownOptions(
          {
            onP2PAction,
            onPaymentPlanAction,
            onRequestPaymentAction,
            onMarkAsDispute,
            onMarkAsResolved,
            onRefund,
            onAssignToProject,
            onDownloadAction,
          },
          models,
          t,
        )}>
        <Button
          className="px-5"
          iconAlign={align.RIGHT}
          iconName="chevronDown"
          label={t('more')}
          size={sizes.SM}
          testData="selection-more"
          variant={buttonsVariants.TERTIARY}
          fullWidth
        />
      </MenuDropdown>
    </Flex>
  )
}

InvoiceActions.propTypes = {
  alignment: PT.string,
  models: PT.arrayOf(
    PT.shape({
      outstandingAmountCents: PT.number,
    }),
  ),
  onDownloadAction: PT.func,
  onP2PAction: PT.func,
  onPaymentPlanAction: PT.func,
  onReceivePaymentAction: PT.func,
  onRequestPaymentAction: PT.func,
  onMarkAsDispute: PT.func,
  onMarkAsResolved: PT.func,
  onRefund: PT.func,
  onAssignToProject: PT.func,
}
InvoiceActions.defaultProps = {
  models: [],
}

export default InvoiceActions
