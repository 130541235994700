import React, { useCallback, useEffect, useState } from 'react'
import PT from 'prop-types'
import { Icon, Dialog, Tooltip } from '../../ui-kit'
import colors from '../../ui-kit/colors'
import { useTranslation } from 'react-i18next'
import { useDirtyFormAlert } from '../../hooks/useDirtyFormAlert'
import CardIntegration from './CardIntegration'
import BankTransferForm from './BankTransferForm'

export const METHODS = {
  CARD: 'creditCard',
  BANK: 'bankTransfer',
  ACH: 'AchPaymentMethod',
}
const NewPaymentMethod = ({
  isOpened,
  setIsOpened,
  contractID,
  onCreateMethod,
  isInner,
  selectedMethod,
  isAchDisabled,
  isCardDisabled,
  paymentMethodDisabledMsg,
}) => {
  const { t } = useTranslation()
  const [method, setMethod] = useState(selectedMethod)

  useEffect(() => {
    setMethod(selectedMethod)
  }, [selectedMethod, isOpened])

  const modalTitle = !method
    ? t('addPaymentMethod')
    : method === METHODS.CARD
    ? t('addCreditCard')
    : method === METHODS.BANK
    ? t('addBankTransferInfo')
    : ''

  const onModalClose = useCallback(() => {
    setMethod(null)
  }, [setMethod])
  const closeForm = useCallback(() => {
    onModalClose()
    setIsOpened(false)
  }, [setIsOpened, onModalClose])
  const { requestClose, isFormDirty, setDirtyFormState } = useDirtyFormAlert({
    closeForm,
    id: 'NewPaymentMethod',
  })
  const handleCreateMethod = useCallback(
    (...args) => {
      setMethod(null)
      onCreateMethod(...args)
    },
    [setMethod, onCreateMethod],
  )

  return (
    <Dialog isInner={isInner} isOpened={isOpened} setIsOpened={requestClose} title={modalTitle}>
      {!method && (
        <div className="flex min-w-max mt-16">
          <Tooltip content={isCardDisabled && paymentMethodDisabledMsg} placement="bottom">
            <button
              className={`border rounded-lg flex  py-8 px-7 items-center w-80 mr-11 cursor-pointer hover:border-primary ${
                isCardDisabled ? 'opacity-60' : ''
              }`}
              onClick={() => !isCardDisabled && setMethod(METHODS.CARD)}
              testData="set-card"
              type="button">
              <Icon className="mr-5" color={colors.PRIMARY} name={METHODS.CARD} type="outline" />

              <span className="black font-medium text-lg">{t('card')}</span>
            </button>
          </Tooltip>

          <Tooltip content={isAchDisabled && paymentMethodDisabledMsg} placement="bottom">
            <button
              className={`border rounded-lg flex  py-8 px-7 items-center w-80 cursor-pointer hover:border-primary ${
                isAchDisabled ? 'opacity-60' : ''
              }`}
              onClick={() => !isAchDisabled && setMethod(METHODS.BANK)}
              testData="set-bank"
              type="button">
              <Icon className="mr-5" color={colors.PRIMARY} name={METHODS.BANK} type="outline" />

              <span className="black font-medium text-lg">{t(METHODS.BANK)}</span>
            </button>
          </Tooltip>
        </div>
      )}

      {method === METHODS.CARD && (
        <div>
          <CardIntegration contractId={contractID} onCreateMethod={handleCreateMethod} />
        </div>
      )}

      {method === METHODS.BANK && (
        <BankTransferForm
          closeForm={closeForm}
          contractId={contractID}
          isFormDirty={isFormDirty}
          onCreateMethod={handleCreateMethod}
          setDirtyFormState={setDirtyFormState}
        />
      )}
    </Dialog>
  )
}

export default NewPaymentMethod

NewPaymentMethod.propTypes = {
  isInner: PT.bool,
  contractID: PT.string.isRequired,
  isOpened: PT.bool.isRequired,
  setIsOpened: PT.func.isRequired,
  onCreateMethod: PT.func,
  selectedMethod: PT.string,
  isAchDisabled: PT.bool,
  isCardDisabled: PT.bool,
  paymentMethodDisabledMsg: PT.string,
}

NewPaymentMethod.defaultProps = {
  isInner: false,
  isOpened: false,
  contractID: '',
}
