import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import Accordion from '../../../ui-kit/components/accordion/Accordion'
import { downloadReport } from '../logic/reportsUtils'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { parseFilterQuery } from '../../../utils/utils'
import { otherReports, otherReportsListMap } from './otherReportsList'
import ActiveUsers from './ActiveUsers'
import InactiveUsers from './InactiveUsers'
import ERPOverrides from './ERPOverrides'
import { useRollbar } from '@rollbar/react'
import { toInteger } from 'lodash'

const OtherReports = ({ authToken, createDetails, createSummary }) => {
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const { queryParams, removeQueryParam } = useQueryParams()
  const page = toInteger(queryParams.page) || 1
  const sort = useMemo(() => queryParams.sort || '', [queryParams])
  const filtersQuery = useMemo(() => queryParams.filters || null, [queryParams])
  const userFilters = useMemo(() => parseFilterQuery(filtersQuery), [filtersQuery])
  const [isExpanded, setIsExpanded] = useState(false)
  const [openedReport, setOpenedReport] = useState('')
  const [reportData, setReportData] = useState(null)
  const summaryProps = useMemo(
    () => ({
      label: t('other'),
      iconName: 'document',
      isExpanded: isExpanded,
    }),
    [isExpanded, t],
  )
  const getReportData = useCallback(
    async (report) => {
      let params = {}

      params.pagination_data = { page, per_page: 100 }

      if (userFilters.length) {
        params.filters = userFilters.reduce((acc, filter) => {
          acc[filter.key] = filter.values
          return acc
        }, {})
      }

      if (report?.defaultFilters) {
        params.filters = { ...params.filters, ...report.defaultFilters }
      }

      if (sort) {
        params.sort = sort
      }

      const data = await downloadReport(report?.jsonUrl, authToken, params, rollbar)
      setReportData(data)
    },
    [setReportData, setOpenedReport, authToken, userFilters, rollbar, sort, page],
  )
  const detailsProps = useMemo(
    () => ({
      reportsMetadata: otherReports,
      setOpenedReport,
    }),
    [setOpenedReport],
  )
  const closeReport = useCallback(() => {
    setOpenedReport('')
    setReportData(null)
    removeQueryParam(['filters', 'sort', 'page'])
  }, [setOpenedReport, setReportData])

  useEffect(() => {
    const fetchReportData = async () => {
      if (openedReport) {
        const report = otherReports[openedReport]
        await getReportData(report)
      }
    }
    fetchReportData()
  }, [openedReport, userFilters, sort, page])

  return (
    <>
      <Accordion
        createDetails={createDetails}
        createSummary={createSummary}
        detailsClassName="divide-y ml-24 mr-8"
        detailsProps={detailsProps}
        id="others-reports"
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        summaryProps={summaryProps}
        wrapperClassName={cx('mt-8', {
          'border border-orange-700': isExpanded,
        })}
      />
      <ActiveUsers
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === otherReportsListMap.ACTIVE_USERS}
        page={page}
        reportMetadata={otherReports[otherReportsListMap.ACTIVE_USERS]}
        userFilters={userFilters}
      />
      <InactiveUsers
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === otherReportsListMap.INACTIVE_USERS}
        page={page}
        reportMetadata={otherReports[otherReportsListMap.INACTIVE_USERS]}
        userFilters={userFilters}
      />
      <ERPOverrides
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === otherReportsListMap.ERP_OVERRIDES}
        page={page}
        reportMetadata={otherReports[otherReportsListMap.ERP_OVERRIDES]}
        userFilters={userFilters}
      />
    </>
  )
}

OtherReports.propTypes = {
  authToken: PT.string.isRequired,
  createDetails: PT.func.isRequired,
  createSummary: PT.func.isRequired,
}

export default OtherReports
