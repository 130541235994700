import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import Accordion from '../../../ui-kit/components/accordion/Accordion'
import { arReportsListMap, arReports } from './arReportsList'
import ArAgingSummary from './ArAgingSummary'
import InvoiceList from './InvoiceList'
import DisputedInvoices from './DisputedInvoices'
import { downloadReport } from '../logic/reportsUtils'
import Credits from './Credits'
import Deposits from './Deposits'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { parseFilterQuery } from '../../../utils/utils'
import ArAgingUserSummary from './ArAgingUserSummary'
import ArAgingLocationSummary from './ArAgingLocationSummary'
import NetDueByCustomer from './NetDueByCustomer'
import { useRollbar } from '@rollbar/react'
import { toInteger } from 'lodash'
import ArAgingUserDetails from './ArAgingUserDetails'

const ArReports = ({ authToken, createDetails, createSummary }) => {
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const { queryParams, removeQueryParam } = useQueryParams()
  const page = toInteger(queryParams.page) || 1
  const sort = useMemo(() => queryParams.sort || '', [queryParams])
  const filtersQuery = useMemo(() => queryParams.filters || null, [queryParams])
  const userFilters = useMemo(() => parseFilterQuery(filtersQuery), [filtersQuery])
  const [isExpanded, setIsExpanded] = useState(false)
  const [openedReport, setOpenedReport] = useState('')
  const [reportData, setReportData] = useState(null)
  const summaryProps = useMemo(
    () => ({
      label: t('arReports'),
      iconName: 'chartPie',
      isExpanded: isExpanded,
    }),
    [isExpanded, t],
  )

  const getReportData = useCallback(
    async (report) => {
      let params = {}

      params.pagination_data = { page, per_page: 100 }

      if (userFilters.length) {
        params.filters = userFilters.reduce((acc, filter) => {
          acc[filter.key] = filter.values
          return acc
        }, {})
      }

      if (report?.defaultFilters) {
        params.filters = { ...params.filters, ...report.defaultFilters }
      }

      if (sort) {
        params.sort = sort
      }

      const data = await downloadReport(report?.jsonUrl, authToken, params, rollbar)
      setReportData(data)
    },
    [setReportData, authToken, userFilters, rollbar, sort, page],
  )
  const detailsProps = useMemo(
    () => ({
      reportsMetadata: arReports,
      setOpenedReport,
    }),
    [setOpenedReport],
  )
  const closeReport = useCallback(() => {
    setOpenedReport('')
    setReportData(null)
    removeQueryParam(['filters', 'sort', 'page'])
  }, [setOpenedReport, setReportData])

  useEffect(() => {
    const fetchReportData = async () => {
      if (openedReport) {
        const report = arReports[openedReport]
        await getReportData(report)
      }
    }
    fetchReportData()
  }, [openedReport, userFilters, sort, page])

  return (
    <>
      <Accordion
        createDetails={createDetails}
        createSummary={createSummary}
        detailsClassName="divide-y ml-24 mr-8"
        detailsProps={detailsProps}
        id="ar-reports"
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        summaryProps={summaryProps}
        wrapperClassName={cx('mt-8', {
          'border border-orange-700': isExpanded,
        })}
      />
      <ArAgingUserDetails
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === arReportsListMap.AR_AGING_USER_DETAILS}
        reportMetadata={arReports[arReportsListMap.AR_AGING_USER_DETAILS]}
        userFilters={userFilters}
      />
      <ArAgingSummary
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === arReportsListMap.AR_AGING_SUMMARY}
        page={page}
        reportMetadata={arReports[arReportsListMap.AR_AGING_SUMMARY]}
        userFilters={userFilters}
      />
      <ArAgingUserSummary
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === arReportsListMap.AR_AGING_USER_SUMMARY}
        page={page}
        reportMetadata={arReports[arReportsListMap.AR_AGING_USER_SUMMARY]}
        userFilters={userFilters}
      />
      <ArAgingLocationSummary
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === arReportsListMap.AR_AGING_LOCATION_SUMMARY}
        page={page}
        reportMetadata={arReports[arReportsListMap.AR_AGING_LOCATION_SUMMARY]}
        userFilters={userFilters}
      />
      <InvoiceList
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === arReportsListMap.INVOICE_LIST}
        page={page}
        reportMetadata={arReports[arReportsListMap.INVOICE_LIST]}
        userFilters={userFilters}
      />
      <DisputedInvoices
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === arReportsListMap.DISPUTED_INVOICES}
        page={page}
        reportMetadata={arReports[arReportsListMap.DISPUTED_INVOICES]}
        userFilters={userFilters}
      />
      <Credits
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === arReportsListMap.CREDITS}
        page={page}
        reportMetadata={arReports[arReportsListMap.CREDITS]}
        userFilters={userFilters}
      />
      <Deposits
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === arReportsListMap.DEPOSITS}
        page={page}
        reportMetadata={arReports[arReportsListMap.DEPOSITS]}
        userFilters={userFilters}
      />
      <NetDueByCustomer
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === arReportsListMap.NET_DUE_BY_CUSTOMER}
        reportMetadata={arReports[arReportsListMap.NET_DUE_BY_CUSTOMER]}
        userFilters={userFilters}
      />
    </>
  )
}

ArReports.propTypes = {
  authToken: PT.string.isRequired,
  createDetails: PT.func.isRequired,
  createSummary: PT.func.isRequired,
}

export default ArReports
