import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, InfoNote, Money, Text } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import getPresentationName from '../../../utils/getPresentationName'
import activityType from '../../../types/activities'
import InvoicesAccordion from '../../../ui-kit/components/invoicesAccordion/InvoicesAccordion'
import { getCreatedByEvent } from '../utils'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import PT from 'prop-types'

const PaymentRequestContent = ({ data, contractId }) => {
  const { t } = useTranslation()

  const outstandingAmount = data?.invoices?.reduce?.((acc, invoice) => {
    if (!invoice?.outstandingAmountCents) {
      return acc
    }

    return acc + invoice.outstandingAmountCents
  }, 0)

  const outstandingRequested = data.requestedAmountCents

  const descriptionData = [
    {
      label: t('currentOutstanding'),
      value: <Money className="text-2xl mt-1" value={outstandingAmount} />,
    },
    { label: t('createdOn'), value: <FormattedDate date={data.createdAt} /> },
    { label: t('createdBy'), value: getCreatedByEvent(data) },
  ]
  const contacts = [...data.recipientsViaEmail, ...data.recipientsViaText]

  return (
    <>
      <div className="flex flex-col mt-[-1.5rem]">
        <Flex column>
          <Flex alignItems={'center'}>
            <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
              {t('paymentRequest')}
            </Text>
          </Flex>

          <Flex>
            <div className="mr-2">
              <InfoNote label={t('id')}>
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                  {data?.entity.paymentRequestId}
                </Text>
              </InfoNote>
            </div>
          </Flex>
          <Flex className="mt-3">
            <Flex className="w-6/12" column>
              <SidebarLabel text="outstandingRequested" />
              <Money className="text-2xl mt-1" value={outstandingRequested} />
            </Flex>
          </Flex>

          <DividedList content={descriptionData} />
          <Flex className={'mt-8'} column>
            <Text fontWeight={fontWeight.MEDIUM} size={sizes.BASE}>
              {t('sentTo')}
            </Text>
            {contacts.map((contact, i) => (
              <Flex className={i === 0 ? '' : 'mt-4'} key={contact.id} column>
                <Text>{contact.user ? getPresentationName(contact, t) : t('guestUser')}</Text>

                <Text color={'blue'}>{contact?.email || contact?.formattedPhoneNumber}</Text>
              </Flex>
            ))}
          </Flex>

          <Textarea
            className="mt-6"
            id="paymentRequestMemo"
            inputClassName="h-32 w-full"
            label={t('memoOptional')}
            name="paymentRequestMemo"
            value={data.memo}
            disabled
          />

          <InvoicesAccordion className={'mt-8'} contractId={contractId} invoices={data.invoices} />
        </Flex>
      </div>
    </>
  )
}

PaymentRequestContent.propTypes = {
  data: activityType,
  contractId: PT.string,
}

PaymentRequestContent.defaultProps = {
  data: {},
}

export default PaymentRequestContent
