import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import { Button, Flex, InfoNote, Money, Text } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import React, { useMemo, useState } from 'react'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import { paymentPromiseStatuses, statusP2PMapping } from './util'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import colors from '../../../ui-kit/colors'
import { PaymentPromisesQuery } from '../../../queries/paymentPromises.gql'
import InvoicesAccordion from '../../../ui-kit/components/invoicesAccordion/InvoicesAccordion'
import { UpdatePaymentPromise } from '../../../queries/mutations/updatePaymentPromise.gql'
import MemoField from '../../../ui-kit/components/inputs/MemoField'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'

const PromiseToPayContent = ({
  data,
  setIsSidebarOpened,
  setIsUpdatePromiseModalOpened,
  setIsOpenedCancelModal,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const paidOn = useMemo(() => {
    const filteredInvoices = data.invoices
      ? data.invoices
          .filter((invoice) => !invoice.outstandingAmountCents)
          .map((invoice) => invoice?.paidWith?.paymentTransaction?.settledAt)
          .sort(function (prev, next) {
            return DateTime.fromISO(next).toMillis() - DateTime.fromISO(prev).toMillis()
          })
      : null
    return filteredInvoices.length ? filteredInvoices[0] : null
  }, [data, t])

  const descriptionData = useMemo(
    () => [
      {
        label: t('status'),
        value: (
          <StatusBadge
            color={statusP2PMapping[data.status]?.color}
            value={t(statusP2PMapping[data.status]?.label)}
          />
        ),
      },
      {
        label: t('paidOn'),
        value: <FormattedDate date={paidOn} />,
        hidden: data.status !== paymentPromiseStatuses.COMPLETED,
      },
      { label: t('expectedPaymentDate'), value: <FormattedDate date={data?.date} /> },
      { label: t('createdOn'), value: <FormattedDate date={data.createdAt} /> },
      { label: t('createdBy'), value: data.createdBy?.fullName },
      {
        label: t('customer'),
        node: (
          <Button
            label={data.contract?.buyer?.name}
            onClick={() => navigate(`/customers/${data.contract?.id}/overview`)}
            size={sizes.SM}
            testData="customer-link"
            variant={buttonsVariants.LINK}
          />
        ),
      },
    ],
    [t, data, navigate],
  )

  const [memoFormIsDirty, setMemoFormIsDirty] = useState(false)

  const [updatePaymentPromise, { loading }] = useCustomMutation({
    mutation: UpdatePaymentPromise,
    rollbarOptions: { operationName: 'UpdatePaymentPromise', target: 'PaymentContent' },
    mutationOptions: {
      refetchQueries: [PaymentPromisesQuery],
    },
  })

  return (
    <div className="flex flex-col mt-[-1.5rem]">
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <Text color={colors.TINYGREY} fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
            {t('promiseToPay')}
          </Text>
        </div>

        <div className="flex flex-row">
          <div className="mr-2">
            <InfoNote label={t('id')}>
              <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                {data?.id}
              </Text>
            </InfoNote>
          </div>
        </div>
      </div>

      <div className="flex flex-row mt-3">
        <div className="w-6/12 flex flex-col">
          <SidebarLabel text="totalAmount" />
          <Money className="text-2xl mt-1" value={data.amountCents} />
        </div>
      </div>

      <DividedList content={descriptionData} />

      {data.status === paymentPromiseStatuses.ACTIVE && (
        <Button
          className="mt-4"
          label={t('editExpectedPaymentDate')}
          onClick={() => {
            setIsSidebarOpened(false)
            setIsUpdatePromiseModalOpened(true)
          }}
          size={sizes.SM}
          testData="edit-promise-date"
          variant={buttonsVariants.LINK}
        />
      )}

      <MemoField
        closeForm={() => null}
        entityId={data.id}
        initialValue={data.memo}
        isDisabled={data.status !== paymentPromiseStatuses.ACTIVE}
        isFormDirty={memoFormIsDirty}
        isLoading={loading}
        responseEntityName={'updatePaymentPromise'}
        setDirtyFormState={setMemoFormIsDirty}
        updateEntity={updatePaymentPromise}
      />
      <Flex className="mt-6" column>
        <InvoicesAccordion contractId={data.contract?.id} invoices={data.invoices} />
        {data.status === paymentPromiseStatuses.ACTIVE && (
          <Button
            className="mt-4"
            label={t('cancelPaymentPromise')}
            onClick={() => {
              setIsSidebarOpened(false)
              setIsOpenedCancelModal(true)
            }}
            size={sizes.BASE}
            testData="cancel-payment-promise"
            variant={buttonsVariants.TERTIARY}
            fullWidth
          />
        )}
      </Flex>
    </div>
  )
}

PromiseToPayContent.propTypes = {
  data: PT.object,
  setIsSidebarOpened: PT.func.isRequired,
  setIsUpdatePromiseModalOpened: PT.func.isRequired,
  setIsOpenedCancelModal: PT.func.isRequired,
}

PromiseToPayContent.defaultProps = {
  data: {},
}

export default PromiseToPayContent
