import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import { Button, Flex, Text, PageDialog, Box, Tooltip, Money, Input, Dialog } from '../../../ui-kit'
import sizes from '../../../ui-kit/sizes'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import fontWeight from '../../../ui-kit/fontWeight'
import arrayMutators from 'final-form-arrays'
import { DateTime } from 'luxon'
import { Field, Form } from 'react-final-form'
import CurrencyInput from '../../../ui-kit/components/inputs/CurrencyInput'
import DatePicker from '../../../ui-kit/components/datePicker/DatePicker'
import FinancialEntity from '../../../components/financialEntity/FinancialEntity'
import { getFinancialEntitiesOptions } from '../../../components/financialEntity/utils'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { FinancialEntitiesQuery } from '../../../queriesUpdated/queries/financialEntity.gql'
import { InvoicesOutstandingForPayment } from '../../../queriesUpdated/queries/invoiceOutstanding.gql'
import { InvoicesAvailableFilters } from '../../../queriesUpdated/queries/common.gql'
import { PaymentMethodsQuery } from '../../../queriesUpdated/queries/paymentMethod.gql'
import { paymentMethodTypes, paymentResultMods } from '../../../constants/paymentResults'
import { getOnlyNumbers, MAX_NUMBER, MAX_PER_PAGE } from '../../../utils/utils'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import {
  checkSupportPartialDiscount,
  getCombinedErrors,
  getDiscount,
  getInvoicesWithUpdatedDiscounts,
  getLoadingFunc,
  getPaymentTypesOptions,
  recordTypes,
  scopeFilterMap,
} from '../../invoices/invoicesUtils'
import { fcIcon } from '../../../ui-kit/assets'
import colors from '../../../ui-kit/colors'
import { paymentMethodsEligibilityEnum } from '../../../constants/paymentMethodEligibilityEnum'
import { parseWithMoneyFormat } from '../../../ui-kit/components/text/Money'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import { FieldArray } from 'react-final-form-arrays'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import rollbar from 'rollbar'
import { FORM_ERROR } from 'final-form'
import { validateRequiredField } from '../../../utils/validators'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { difference, snakeCase } from 'lodash'
import AddMemoForm from './addMemoForm/AddMemoForm'
import { CreditsDepositsQuery } from '../../../queriesUpdated/queries/common.gql'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import { ProjectsAutocompleteQuery } from '../../../queriesUpdated/queries/project.gql'
import {
  getDifDays,
  getFormattedDate,
  getLuxonDate,
  SERVER_DATE,
  SERVER_DATE_FORMAT,
} from '../../../ui-kit/utils/dateUtils'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { getTransactionPaymentResult } from '../../../utils/paymentResultsUtils'
import { CreatePaymentMutation } from '../../../queriesUpdated/mutations/createPayment.gql'
import { PaymentTransactionProgressQuery } from '../../../queriesUpdated/queries/paymentTransaction.gql'
import PaymentResult from '../../../components/paymentResult/PaymentResult'
import { useApolloClient } from '@apollo/client'
import PaymentType from '../../../components/paymentType/PaymentType'
import FiltersControlButton from '../../../components/filters/FiltersControlButton'
import UpdateAttachmentForm from './updateAttachmentForm/UpdateAttachmentForm'
import { useAllRecordsFetch } from '../../../hooks/useAllRecordsFetch'

const CashApp = ({ contractData, contractId, isOpened, setIsOpened, withPartialPayment }) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const currentUser = useCurrentUser()
  const [sort, setSort] = useState('')
  const [sortModel, setSortModel] = useState([])
  const [search, setSearch] = useState('')
  const formAPI = useRef(null)
  const gridAPI = useRef(null)
  const [isAddMemoModalOpened, setIsAddMemoModalOpened] = useState(false)
  const [isSelectCreditsDepositsModalOpened, setIsSelectCreditsDepositsModalOpened] =
    useState(false)
  const [isAttachmentMemoModalOpened, setIsAttachmentMemoModalOpened] = useState(false)
  const [isReceivePaymentButtonDisabled, setIsReceivePaymentButtonDisabled] = useState(false)
  const [paymentResult, setPaymentResult] = useState(null)
  const [paymentTransactionId, setPaymentTransactionId] = useState(null)
  const [paymentStatusRefreshCounter, setPaymentStatusRefreshCounter] = useState(0)
  const [failureReason, setFailureReason] = useState(null)
  const isSupportPartialDiscount = currentUser.erpMetadata?.isSupportPartialDiscount

  // Filters
  const [filters, setFilters] = useState({})
  const [availableFilters, setAvailableFilters] = useState(null)
  const { refetch: refetchProjectsLoadingData } = useCustomQuery({
    query: ProjectsAutocompleteQuery,
    rollbarOptions: { operationName: 'ProjectsAutocompleteQuery', target: 'CashApp' },
  })
  const loadProjectOptions = getLoadingFunc(
    refetchProjectsLoadingData,
    'projectsAutocomplete',
    null,
    {
      contractId,
    },
  )

  // Invoice block
  const [isGlobalPrefillRequired, setIsGlobalPrefillRequired] = useState(false)
  const [selectedInvoicesIds, setSelectedInvoicesIds] = useState([])
  const { resetRecords, records, isReady, refetchRecords, paginationData } = useAllRecordsFetch({
    query: InvoicesOutstandingForPayment,
    entityName: 'invoices',
    queryOptions: {
      variables: {
        filters: [
          scopeFilterMap.outstanding,
          {
            key: 'contract_id',
            values: [contractId],
          },
          {
            key: 'eligible_for_payment',
            values: ['true'],
          },
          ...Object.keys(filters).reduce((acc, filterKey) => {
            if (filters[filterKey]?.length) {
              acc.push({
                key: snakeCase(filterKey),
                values: filters[filterKey],
              })
            }

            return acc
          }, []),
        ].filter(Boolean),
        sort,
        search,
        perPage: MAX_PER_PAGE,
        contractId,
      },
    },
    rollbarOptions: {
      operationName: 'InvoicesOutstandingForPayment',
      target: 'CashApp',
    },
  })
  const refetchInvoices = () => {
    refetchRecords()
  }
  useEffect(() => {
    refetchInvoices()
  }, [contractId, filters, sort, search])

  const getInvoicesRows = (fields) =>
    fields?.map((name, index) => {
      const invoice = fields.value[index]
      return {
        fieldName: name,
        invoiceIndex: index,
        ...invoice,
      }
    }) || []
  const getInvoicesColumns = (form) => [
    {
      field: 'invoiceNumber',
      headerName: t('number'),
      renderCell: (values) => <span className="pt-1">{values?.row?.invoiceNumber}</span>,
      flex: 2,
    },
    {
      field: 'fcIcon',
      headerName: '',
      sortable: false,
      renderCell: (values) =>
        values.row.recordType === recordTypes.FINANCE_CHARGE && (
          <Tooltip content={t('financeCharges')}>
            <img alt="Suppli" className="h-5 mt-1" src={fcIcon} />
          </Tooltip>
        ),
      width: 40,
    },
    {
      field: 'projectName',
      headerName: t('project'),
      filterId: 'projectName',
      loadOptions: loadProjectOptions,
      renderCell: (values) => (
        <Text className="pt-1" color={colors.GRAY_500}>
          {values?.row?.project?.name}
        </Text>
      ),
      flex: 3,
    },
    {
      field: 'maturityDate',
      headerName: t('dueDate'),
      renderCell: (values) => (
        <Text className="pt-1" color="text-warmBlack-500">
          <FormattedDate date={values?.row?.maturityDate} />
        </Text>
      ),
      width: 100,
    },
    {
      field: 'outstandingAmountCents',
      headerName: t('outstanding'),
      renderCell: (values) => (
        <Money className="pt-1" value={values?.row?.outstandingAmountCents} />
      ),
      width: 150,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'appliedDiscountAmountCents',
      headerName: t('discounts'),
      renderCell: (values) =>
        withPartialPayment ? (
          values?.row?.discountAmountCents ? (
            <Field name={`${values?.row?.fieldName}.appliedDiscountAmountCents`}>
              {({ input, meta }) => (
                <CurrencyInput
                  className="ml-1"
                  disabled={
                    !selectedInvoicesIds.includes(values?.row?.id) || !isSupportPartialDiscount
                  }
                  error={meta.error}
                  helperText={t('availableAmountHint', {
                    amount: parseWithMoneyFormat(values?.row?.discountAmountCents),
                  })}
                  id={`${values?.row?.id}-appliedDiscountAmountCents`}
                  onChange={(e) => {
                    const value = e.target.value
                    let appliedDiscountAmountCents = Math.round(value * 100)

                    if (appliedDiscountAmountCents > values?.row?.discountAmountCents) {
                      appliedDiscountAmountCents = values?.row?.discountAmountCents
                    }

                    const newPaymentCents =
                      values?.row?.paymentCents + appliedDiscountAmountCents >
                      values?.row?.outstandingAmountCents
                        ? values?.row?.outstandingAmountCents - appliedDiscountAmountCents
                        : values?.row?.paymentCents
                    const newPaymentPercent = parseInt(
                      Math.fround(
                        ((newPaymentCents + appliedDiscountAmountCents) * 100) /
                          values?.row?.outstandingAmountCents,
                      ),
                    )
                    form.mutators.update('invoices', values?.row?.invoiceIndex, {
                      ...values?.row,
                      appliedDiscountAmountCents,
                      paymentCents: newPaymentCents,
                      paymentPercent: newPaymentPercent,
                    })
                  }}
                  testData="charges-input"
                  value={input.value / 100}
                  isTableInput
                />
              )}
            </Field>
          ) : (
            <span>—</span>
          )
        ) : (
          <Money className="pt-1" value={values?.row?.appliedDiscountAmountCents} />
        ),
      width: 150,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'paymentCents',
      headerName: t('payment'),
      renderCell: (values) =>
        withPartialPayment ? (
          <Field name={`${values?.row?.fieldName}.paymentCents`}>
            {({ input, meta }) => (
              <CurrencyInput
                disabled={!selectedInvoicesIds.includes(values?.row?.id)}
                error={meta.error}
                id={`${values?.row?.id}-paymentCents`}
                onChange={(e) => {
                  const value = e.target.value
                  let newValue = Math.round(value * 100)

                  if (
                    newValue >
                    values?.row?.outstandingAmountCents - values?.row?.appliedDiscountAmountCents
                  ) {
                    newValue =
                      values?.row?.outstandingAmountCents - values?.row?.appliedDiscountAmountCents
                  }

                  const appliedDiscountAmountCents = checkSupportPartialDiscount(
                    isSupportPartialDiscount,
                    values?.row?.discountAmountCents,
                    {
                      paymentCents: newValue + values?.row?.appliedDiscountAmountCents,
                      outstandingAmountCents: values?.row?.outstandingAmountCents,
                    },
                  )

                  const newPaymentPercent = parseInt(
                    Math.fround(
                      ((newValue + values?.row?.appliedDiscountAmountCents) * 100) /
                        values?.row?.outstandingAmountCents,
                    ),
                  )
                  form.mutators.update('invoices', values?.row?.invoiceIndex, {
                    ...values?.row,
                    paymentCents: newValue,
                    paymentPercent: newPaymentPercent,
                    appliedDiscountAmountCents,
                  })
                }}
                testData="payment-input"
                value={input.value / 100}
                isTableInput
              />
            )}
          </Field>
        ) : (
          <Money value={values?.row?.paymentCents} />
        ),
      width: 150,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'paymentPercent',
      headerName: '',
      renderCell: (values) =>
        withPartialPayment ? (
          <Field name={`${values?.row?.fieldName}.paymentPercent`}>
            {({ input, meta }) => (
              <Input
                className="relative"
                disabled={!selectedInvoicesIds.includes(values?.row?.id)}
                error={meta.error}
                icon={<div>%</div>}
                id={`${values?.row?.id}-paymentPercent`}
                onBlur={(e) => {
                  if (e.target.value === '') {
                    form.mutators.update('invoices', values?.row?.invoiceIndex, {
                      ...values?.row,
                      appliedDiscountAmountCents: values?.row?.discountAmountCents,
                      paymentCents:
                        values?.row?.outstandingAmountCents - values?.row?.discountAmountCents,
                      paymentPercent: 100,
                    })
                  }
                }}
                onChange={(e) => {
                  let newPaymentPercent = getOnlyNumbers(e.target.value)

                  if (newPaymentPercent > 100) {
                    newPaymentPercent = 100
                  }

                  const applicableCents = Math.round(
                    (newPaymentPercent * values?.row?.outstandingAmountCents) / 100,
                  )
                  let paymentCents

                  let appliedDiscountAmountCents = checkSupportPartialDiscount(
                    isSupportPartialDiscount,
                    values?.row?.discountAmountCents,
                    {
                      paymentCents: applicableCents,
                      outstandingAmountCents: values?.row?.outstandingAmountCents,
                    },
                  )

                  if (appliedDiscountAmountCents > applicableCents) {
                    appliedDiscountAmountCents = applicableCents
                    paymentCents = 0
                  } else {
                    paymentCents =
                      Math.round((newPaymentPercent * values?.row?.outstandingAmountCents) / 100) -
                      appliedDiscountAmountCents
                  }

                  form.mutators.update('invoices', values?.row?.invoiceIndex, {
                    ...values?.row,
                    appliedDiscountAmountCents,
                    paymentCents,
                    paymentPercent: newPaymentPercent,
                  })
                }}
                testData="payment-percent-input"
                value={input.value}
                isTableInput
              />
            )}
          </Field>
        ) : (
          <Text color={colors.GRAY_500}>{values?.row?.paymentPercent}%</Text>
        ),
      width: 78,
      sortable: false,
    },
  ]
  const getPrefilledInvoice = ({ invoiceId, invoice, paymentCentsLimit }) => {
    let targetInvoiceIndex, targetInvoice

    if (invoiceId) {
      const values = formAPI.current.getState().values
      targetInvoiceIndex = values.invoices.findIndex((invoice) => invoice.id === invoiceId)
      targetInvoice = values.invoices[targetInvoiceIndex]
    } else if (invoice) {
      targetInvoice = invoice
    }

    if (!targetInvoice) {
      return
    }

    let appliedDiscountAmountCents = targetInvoice.discountAmountCents || 0
    let paymentCents = 0

    if (paymentCentsLimit <= 0) {
      if (!appliedDiscountAmountCents) {
        return
      }

      if (appliedDiscountAmountCents >= targetInvoice.outstandingAmountCents) {
        appliedDiscountAmountCents = targetInvoice.outstandingAmountCents
      }
    } else {
      if (targetInvoice.outstandingAmountCents > paymentCentsLimit) {
        if (appliedDiscountAmountCents < targetInvoice.outstandingAmountCents) {
          paymentCents =
            targetInvoice.outstandingAmountCents - appliedDiscountAmountCents > paymentCentsLimit
              ? paymentCentsLimit
              : targetInvoice.outstandingAmountCents - appliedDiscountAmountCents
        }
      } else {
        paymentCents = targetInvoice.outstandingAmountCents - appliedDiscountAmountCents
      }
    }

    const paymentPercent = parseInt(
      Math.fround(
        ((paymentCents + appliedDiscountAmountCents) * 100) / targetInvoice.outstandingAmountCents,
      ),
    )

    if (!isSupportPartialDiscount && paymentPercent < 100) {
      appliedDiscountAmountCents = 0
    }

    return {
      index: targetInvoiceIndex,
      invoice: { ...targetInvoice, paymentCents, paymentPercent, appliedDiscountAmountCents },
    }
  }
  const handleSelectionModelChange = useCallback(
    (model) => {
      setSelectedInvoicesIds(model)

      if (isGlobalPrefillRequired || !isReady) {
        return
      }

      if (model.length && model.length > selectedInvoicesIds.length) {
        const nextSelectedInvoicesIds = difference(model, selectedInvoicesIds)
        let { totalPaymentCents, leftToApplyAmountCents } = getPaymentSummary(
          formAPI.current.getState().values,
        )

        for (const invoiceId of nextSelectedInvoicesIds) {
          const paymentCentsLimit = currentUser.erpMetadata?.zeroNetPaymentAllowed
            ? totalPaymentCents
              ? leftToApplyAmountCents
              : 0
            : leftToApplyAmountCents
          const prefilledData = getPrefilledInvoice({
            invoiceId,
            paymentCentsLimit: withPartialPayment ? paymentCentsLimit : void 0,
          })

          if (prefilledData?.index < 0 || !prefilledData?.invoice) {
            break
          }

          formAPI.current.mutators.update('invoices', prefilledData.index, prefilledData.invoice)

          if (
            prefilledData.invoice.appliedDiscountAmountCents &&
            prefilledData.invoice.paymentCents === 0
          ) {
            continue
          }

          leftToApplyAmountCents =
            prefilledData.invoice.paymentCents && prefilledData.invoice.appliedDiscountAmountCents
              ? leftToApplyAmountCents - prefilledData.invoice.paymentCents
              : leftToApplyAmountCents -
                (prefilledData.invoice.paymentCents +
                  prefilledData.invoice.appliedDiscountAmountCents)
        }
      } else {
        const nextSelectedInvoicesIds = difference(selectedInvoicesIds, model)
        const values = formAPI.current.getState().values

        for (const invoiceId of nextSelectedInvoicesIds) {
          const targetInvoiceIndex = values.invoices.findIndex(
            (invoice) => invoice.id === invoiceId,
          )
          const targetInvoice = values.invoices[targetInvoiceIndex]

          if (targetInvoice.paymentCents || targetInvoice.appliedDiscountAmountCents) {
            formAPI.current.mutators.update('invoices', targetInvoiceIndex, {
              ...targetInvoice,
              paymentCents: 0,
              paymentPercent: 0,
              appliedDiscountAmountCents: 0,
            })
          }
        }
      }
    },
    [isReady, selectedInvoicesIds, isGlobalPrefillRequired],
  )
  useEffect(() => {
    if (!isGlobalPrefillRequired || !isReady) {
      return () => ({})
    }

    const nextSelectedInvoicesIds = []
    const values = formAPI.current.getState().values
    const { invoices } = values
    let { leftToApplyAmountCents } = getPaymentSummary(values)

    for (const invoiceIndex in invoices) {
      const invoice = invoices[invoiceIndex]
      const prefilledData = getPrefilledInvoice({
        invoice,
        paymentCentsLimit: withPartialPayment ? leftToApplyAmountCents : void 0,
      })

      if (!prefilledData?.invoice) {
        break
      }

      if (
        withPartialPayment ||
        (!withPartialPayment &&
          leftToApplyAmountCents -
            (prefilledData.invoice.paymentCents +
              prefilledData.invoice.appliedDiscountAmountCents) >=
            0)
      ) {
        formAPI.current.mutators.update('invoices', invoiceIndex, prefilledData.invoice)
        nextSelectedInvoicesIds.push(prefilledData.invoice.id)
      }

      if (
        prefilledData.invoice.appliedDiscountAmountCents &&
        prefilledData.invoice.paymentCents === 0
      ) {
        continue
      }

      leftToApplyAmountCents =
        prefilledData.invoice.paymentCents && prefilledData.invoice.appliedDiscountAmountCents
          ? leftToApplyAmountCents - prefilledData.invoice.paymentCents
          : leftToApplyAmountCents -
            (prefilledData.invoice.paymentCents + prefilledData.invoice.appliedDiscountAmountCents)
    }

    if (nextSelectedInvoicesIds.length) {
      gridAPI.current.setRowSelectionModel(nextSelectedInvoicesIds)
    }

    setIsGlobalPrefillRequired(false)
  }, [isGlobalPrefillRequired, isReady])
  const onCompletedFilters = useCallback((response) => {
    response?.invoices?.availableFilters && setAvailableFilters(response.invoices.availableFilters)
  }, [])
  useCustomQuery({
    query: InvoicesAvailableFilters,
    onCompleted: onCompletedFilters,
    rollbarOptions: { operationName: 'InvoicesAvailableFilters', target: 'CashApp' },
  })

  // Credits and Deposits block
  const {
    data: creditsDeposits,
    loading: creditsDepositsLoading,
    refetch: refetchCreditsDeposits,
  } = useCustomQuery({
    query: CreditsDepositsQuery,
    queryOptions: {
      skip: !contractId,
      variables: {
        filtersDeposits: [
          { key: 'contract_id', values: [contractId] },
          { key: 'outstanding_amount', values: ['1', ''] },
          { key: 'readonly', values: 'false' },
        ],
        filtersCredits: [
          { key: 'contract_id', values: [contractId] },
          { key: 'outstanding_amount', values: ['1', ''] },
          { key: 'readonly', values: 'false' },
        ],
      },
    },
    rollbarOptions: {
      operationName: 'CreditsDepositsQuery',
      target: 'CashApp',
    },
  })
  const getCreditsDepositsRows = (fields) =>
    fields?.map((name, index) => {
      const invoice = fields.value[index]
      return {
        fieldName: name,
        entityIndex: index,
        ...invoice,
      }
    }) || []
  const getCreditsDepositsColumns = (form) => [
    {
      field: 'entityTypeAndAmount',
      headerName: '',
      renderCell: (values) => (
        <Flex className="h-full justify-evenly text-ellipsis overflow-hidden" column>
          <Tooltip content={values?.row?.memo}>
            <Text>{`${t(values?.row?.type)}: ${values?.row?.displayId}`}</Text>
          </Tooltip>
          <Tooltip content={values?.row?.project?.name}>
            <Text color="text-warmBlack-400 text-ellipsis overflow-hidden block max-w-[16rem]">{`${t(
              'project',
            )}: ${values?.row?.project?.name || '-'}`}</Text>
          </Tooltip>
          <Text color="text-warmBlack-400">
            {t('availableAmountHint', {
              amount: parseWithMoneyFormat(values?.row?.outstandingAmountCents),
            })}
          </Text>
        </Flex>
      ),
      flex: 3,
      sortable: false,
    },
    {
      field: 'enablingToggle',
      headerName: '',
      renderCell: (values) => (
        <Flex alignItems="center" className="h-full">
          <Field name={`${values?.row?.fieldName}.isEntityApplied`}>
            {({ input }) => (
              <Toggle
                className="pt-0"
                handleChange={(state) => {
                  if (!state) {
                    form.mutators.update('creditsDeposits', values?.row?.entityIndex, {
                      ...values?.row,
                      isEntityApplied: state,
                      appliedAmountCents: 0,
                    })
                    return
                  }

                  const formValues = form.getState().values
                  const paymentSummary = getPaymentSummary(formValues)
                  const targetEntity = formValues.creditsDeposits[values?.row?.entityIndex]

                  let appliedAmountCents

                  if (
                    (values?.row?.type === 'credit' &&
                      !currentUser.erpMetadata?.partialCreditApplicationEnabled) ||
                    (paymentSummary.netPaymentCents === 0 &&
                      paymentSummary.leftToApplyAmountCents === 0)
                  ) {
                    appliedAmountCents = targetEntity.outstandingAmountCents
                  } else if (paymentSummary.leftToApplyAmountCents >= 0) {
                    appliedAmountCents = targetEntity.outstandingAmountCents
                  } else {
                    appliedAmountCents =
                      paymentSummary.leftToApplyAmountCents * -1 >
                      targetEntity.outstandingAmountCents
                        ? targetEntity.outstandingAmountCents
                        : paymentSummary.leftToApplyAmountCents * -1
                  }

                  form.mutators.update('creditsDeposits', values?.row?.entityIndex, {
                    ...values?.row,
                    isEntityApplied: state,
                    appliedAmountCents,
                  })
                }}
                testData={`isApplyCreditDeposit-${values?.row?.type}`}
                value={input.value}
              />
            )}
          </Field>
        </Flex>
      ),
      flex: 1,
      sortable: false,
    },
    {
      field: 'appliedAmountCents',
      headerName: '',
      renderCell: (values) => (
        <Flex alignItems="center" className="h-full w-full" justifyContent="end">
          <Field name={`${values?.row?.fieldName}.appliedAmountCents`}>
            {({ input, meta }) =>
              values?.row?.type === 'credit' &&
              !currentUser.erpMetadata?.partialCreditApplicationEnabled ? (
                <Money className="items-center" value={input.value} />
              ) : (
                <CurrencyInput
                  disabled={!values?.row?.isEntityApplied}
                  error={meta.error}
                  id={`${values?.row?.id}-appliedAmountCents`}
                  onChange={(e) => {
                    const value = e.target.value
                    const newValue = Math.round(value * 100)
                    input.onChange(newValue)
                  }}
                  testData={`${values?.row?.type}-input`}
                  value={input.value / 100}
                  isTableInput
                />
              )
            }
          </Field>
        </Flex>
      ),
      flex: 2,
      sortable: false,
    },
  ]

  // Payment methods
  const [paymentMethods, setPaymentMethods] = useState([])
  const onPaymentMethodsCompleted = useCallback((response) => {
    setPaymentMethods(getPaymentTypesOptions(response, t))
  }, [])
  const {
    data: paymentMethodsData,
    loading: paymentMethodsLoading,
    refetch: refetchPaymentMethods,
  } = useCustomQuery({
    query: PaymentMethodsQuery,
    onCompleted: onPaymentMethodsCompleted,
    queryOptions: {
      variables: {
        contractId: contractId,
        eligibility: paymentMethodsEligibilityEnum.PAYMENT,
        includeMetaInfo: true,
        includeContractPaymentSettings: true,
      },
      skip: !contractId,
    },
    rollbarOptions: {
      target: 'CashApp',
      operationName: 'PaymentMethodsQuery',
    },
  })

  // Financial entity
  const [financialEntitiesOptions, setFinancialEntitiesOptions] = useState([])
  const onCompletedFinancialEntities = useCallback((response) => {
    const options = getFinancialEntitiesOptions(response?.financialEntities)
    setFinancialEntitiesOptions(options)
  }, [])
  const { loading: financialEntitiesLoading, refetch: refetchFinancialEntities } = useCustomQuery({
    query: FinancialEntitiesQuery,
    onCompleted: onCompletedFinancialEntities,
    queryOptions: {
      skip: !isOpened,
    },
    rollbarOptions: {
      operationName: 'FinancialEntitiesQuery',
      target: 'CashApp',
    },
  })

  // Payment mutations and status validation
  const [createPayment, { loading: requestInProgress }] = useCustomMutation({
    mutation: CreatePaymentMutation,
    rollbarOptions: {
      operationName: 'CreatePaymentMutation',
      target: 'CashApp',
    },
  })
  const { refetch: refetchPayment } = useCustomQuery({
    query: PaymentTransactionProgressQuery,
    queryOptions: {
      variables: {
        id: paymentTransactionId,
      },
      skip: !paymentTransactionId,
    },
    rollbarOptions: {
      operationName: 'PaymentTransactionProgressQuery',
      target: 'CashApp',
    },
  })
  useEffect(() => {
    let timeoutId
    const fetchPaymentStatus = async () => {
      if (paymentStatusRefreshCounter === 0) {
        return
      }

      if (!paymentTransactionId) {
        setPaymentResult(paymentResultMods.SERVER_ERROR)

        return
      }

      const { data: loadedData } = await refetchPayment()
      const paymentTransaction = loadedData?.paymentTransaction

      const transactionPaymentResult = getTransactionPaymentResult(
        paymentTransaction,
        setFailureReason,
      )

      if (transactionPaymentResult) {
        setPaymentResult(transactionPaymentResult)
        return
      }

      timeoutId = setTimeout(() => {
        setPaymentStatusRefreshCounter((prevState) => prevState + 1)
      }, 3000)
    }

    fetchPaymentStatus()

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [paymentStatusRefreshCounter, refetchPayment, paymentTransactionId])

  // Component logic
  const hiddenValidationValue = 'HIDDEN'
  const modalTitle = useMemo(
    () => (
      <Text fontWeight={fontWeight.BOLD} size={sizes.XL}>
        {t('applyPayment')} |{' '}
        <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL}>
          {contractData?.buyer?.name}
        </Text>
      </Text>
    ),
    [contractData],
  )
  const getPaymentSummary = (values) => {
    const { appliedAmountCents, totalAppliedDiscountAmountCents } = (values?.invoices || []).reduce(
      (acc, invoice) => {
        if (!selectedInvoicesIds.includes(invoice.id)) {
          return acc
        }

        acc.appliedAmountCents += invoice.paymentCents + invoice.appliedDiscountAmountCents
        acc.totalAppliedDiscountAmountCents += invoice.appliedDiscountAmountCents
        return acc
      },
      {
        appliedAmountCents: 0,
        totalAppliedDiscountAmountCents: 0,
      },
    )

    const {
      anyCreditsDepositsExists,
      totalAppliedCreditAmountCents,
      totalAppliedDepositAmountCents,
    } = (values?.creditsDeposits || []).reduce(
      (acc, entity) => {
        if (entity.outstandingAmountCents) {
          acc.anyCreditsDepositsExists = true
        }

        if (entity.isEntityApplied && entity.type === 'credit') {
          acc.totalAppliedCreditAmountCents += entity.appliedAmountCents
        }

        if (entity.isEntityApplied && entity.type === 'deposit') {
          acc.totalAppliedDepositAmountCents += entity.appliedAmountCents
        }

        return acc
      },
      {
        anyCreditsDepositsExists: false,
        totalAppliedCreditAmountCents: 0,
        totalAppliedDepositAmountCents: 0,
      },
    )

    const netPaymentCents = values?.enteredNetPaymentAmountCents || 0
    const totalPaymentCents =
      netPaymentCents +
      totalAppliedCreditAmountCents +
      totalAppliedDepositAmountCents +
      totalAppliedDiscountAmountCents
    const leftToApplyAmountCents = totalPaymentCents - appliedAmountCents

    return {
      netPaymentCents,
      totalPaymentCents,
      appliedAmountCents,
      leftToApplyAmountCents,
      anyCreditsDepositsExists,
      totalAppliedCreditAmountCents,
      totalAppliedDepositAmountCents,
      totalAppliedDiscountAmountCents,
    }
  }
  const getPaymentDescriptionData1 = (paymentSummary) => [
    {
      label: t('netPayment'),
      value: <Money value={paymentSummary.netPaymentCents} />,
    },
    {
      label: `(+) ${t('credits')}`,
      value: <Money value={paymentSummary.totalAppliedCreditAmountCents} />,
    },
    {
      label: `(+) ${t('deposits')}`,
      value: <Money value={paymentSummary.totalAppliedDepositAmountCents} />,
    },
    {
      label: `(+) ${t('discounts')}`,
      value: <Money value={paymentSummary.totalAppliedDiscountAmountCents} />,
    },
  ]
  const getPaymentDescriptionData2 = (paymentSummary) => [
    {
      label: `(-) ${t('amountApplied')}`,
      value: <Money value={paymentSummary.appliedAmountCents} />,
    },
  ]

  const getInitialValues = useCallback(() => {
    const invoices = isReady ? records : []
    const { deposits, credits } = creditsDeposits || {}
    let file,
      paymentDate,
      financialEntityId,
      filledCreditsDeposits,
      externalPaymentMethod,
      enteredNetPaymentAmountCents

    if (formAPI.current) {
      const values = formAPI.current.getState().values
      file = values.file
      paymentDate = values.paymentDate
      financialEntityId = values.financialEntityId
      filledCreditsDeposits = values.creditsDeposits
      externalPaymentMethod = values.paymentMethod
      enteredNetPaymentAmountCents = values.enteredNetPaymentAmountCents
    }

    if (!paymentDate) {
      paymentDate = DateTime.now().toJSDate()
    }

    if (!financialEntityId) {
      financialEntityId = externalPaymentMethod
        ? financialEntitiesOptions.find((option) => option.isDefault)?.value || null
        : null
    }

    if (!filledCreditsDeposits || !filledCreditsDeposits?.length) {
      const extendedCredits =
        credits?.data?.map((credit) => ({
          ...credit,
          type: 'credit',
          displayId: credit.referenceId,
          isEntityApplied: false,
          appliedAmountCents: 0,
        })) || []
      const extendedDeposits =
        deposits?.data.map((deposit) => ({
          ...deposit,
          type: 'deposit',
          displayId: currentUser.erpMetadata?.supportDeposits ? deposit.id : deposit.referenceId,
          isEntityApplied: false,
          appliedAmountCents: 0,
        })) || []
      filledCreditsDeposits = [...extendedCredits, ...extendedDeposits]
    }

    const filledInvoices = invoices.map((invoice) => {
      const newInvoice = {
        ...invoice,
        paymentCents: invoice.outstandingAmountCents,
        paymentPercent: 100,
      }
      const { discountAmountCents } = getDiscount({
        invoice,
        paymentMethodType: externalPaymentMethod?.type,
        selectedPaymentDate: paymentDate,
        isSupportPartialDiscount,
        rollbar,
      })

      return {
        ...newInvoice,
        paymentCents: 0,
        paymentPercent: 0,
        discountAmountCents,
        appliedDiscountAmountCents: 0,
      }
    })

    return {
      memo: '',
      file,
      invoices: filledInvoices,
      paymentDate,
      paymentMethod: externalPaymentMethod,
      creditsDeposits: filledCreditsDeposits,
      financialEntityId,
      enteredNetPaymentAmountCents: enteredNetPaymentAmountCents || 0,
    }
  }, [
    records,
    isReady,
    creditsDeposits,
    paymentMethodsData,
    financialEntitiesOptions,
    currentUser.erpMetadata,
  ])
  const initialValues = useMemo(
    () => getInitialValues(),
    [records, isReady, creditsDeposits, paymentMethodsData, financialEntitiesOptions],
  )

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel)
    const model = newModel[0]
    resetRecords()

    if (!model) {
      setSort('')
    } else {
      const targetEntity = snakeCase(model.field || '')
      setSort(`${targetEntity}.${model.sort}`)
    }
  }
  const applySorting = (nextSort, withPrefill = true) => {
    if (nextSort) {
      const [field, direction] = nextSort.split('.')
      gridAPI.current.setSortModel([{ field, sort: direction }])
    } else {
      sort && gridAPI.current.setSortModel([])
    }

    withPrefill && setIsGlobalPrefillRequired(true)
  }
  const handleValidateInvoices = (values) => {
    if (!values) {
      return
    }

    const errors = values.map((invoice) => {
      if (!selectedInvoicesIds.includes(invoice.id)) {
        return
      }

      const invoiceErrors = {}

      if (invoice.appliedDiscountAmountCents > invoice.discountAmountCents) {
        invoiceErrors.appliedDiscountAmountCents = t('invalidDiscount')
      }

      if (
        invoice.paymentCents + invoice.appliedDiscountAmountCents >
        invoice.outstandingAmountCents
      ) {
        invoiceErrors.paymentCents = t('invoiceInvalidPaymentAmount')
      } else if (invoice.paymentCents === 0) {
        invoiceErrors.paymentCents = t('invoicePaymentAmountValidation')
      } else if (
        !isSupportPartialDiscount &&
        invoice.outstandingAmountCents !== invoice.paymentCents &&
        invoice.discountAmountCents > invoice.outstandingAmountCents - invoice.paymentCents &&
        (!invoice.discountCutoffDate ||
          getDifDays(
            DateTime.now(),
            getLuxonDate(invoice.discountCutoffDate, SERVER_DATE, rollbar),
          ) < 1)
      ) {
        invoiceErrors.paymentCents = t('paymentCanBeGreaterAvailableDiscount', {
          amount: parseWithMoneyFormat(invoice.discountAmountCents),
        })
      }

      return Object.keys(invoiceErrors).length ? invoiceErrors : undefined
    })

    return errors.filter(Boolean).length ? errors : undefined
  }
  const handleValidateForm = (values) => {
    let formError
    const paymentSummary = getPaymentSummary(values)
    const { netPaymentCents, leftToApplyAmountCents } = paymentSummary

    if (netPaymentCents === 0 && !currentUser.erpMetadata?.zeroNetPaymentAllowed) {
      formError = t('invalidZeroNetPayment')
    } else if (
      leftToApplyAmountCents !== 0 ||
      (currentUser.erpMetadata?.zeroNetPaymentAllowed && !selectedInvoicesIds.length)
    ) {
      formError = hiddenValidationValue
    } else if (
      values.paymentMethod?.type === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD &&
      !values.financialEntityId
    ) {
      formError = t('depositToNotSelected')
    }

    return {
      [FORM_ERROR]: formError,
      paymentMethod: validateRequiredField(values.paymentMethod),
      financialEntityId:
        values.paymentMethod?.type === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD
          ? validateRequiredField(values.financialEntityId)
          : void 0,
      invoices: handleValidateInvoices(values.invoices),
    }
  }
  const handleValidateCreditsDeposits = (values) => {
    const errors = values.map((entity) => {
      const entityErrors = {}

      if (entity.appliedAmountCents > entity.outstandingAmountCents) {
        entityErrors.appliedAmountCents =
          entity.type === 'credit' ? t('invalidCreditTotal') : t('invalidDepositTotal')
      } else if (entity.isEntityApplied && entity.appliedAmountCents == 0) {
        entityErrors.appliedAmountCents = t('appliedAmountValidation')
      }

      return Object.keys(entityErrors).length ? entityErrors : undefined
    })

    return errors.filter(Boolean).length ? errors : undefined
  }
  const validateNavigationButtonsState = (formErrors) => {
    setIsReceivePaymentButtonDisabled(!!Object.values(formErrors).filter(Boolean).length)
  }
  const handleSubmit = () => {
    const values = formAPI.current.getState().values
    const paymentSummary = getPaymentSummary(values)
    const { creditsData, depositsData } = values.creditsDeposits.reduce(
      (acc, entity) => {
        if (!entity.isEntityApplied) {
          return acc
        }

        const entityData = {
          id: entity.id,
          appliedAmountCents: entity.appliedAmountCents,
        }

        if (entity.type === 'credit') {
          acc.creditsData.push(entityData)
        } else if (entity.type === 'deposit') {
          acc.depositsData.push(entityData)
        }

        return acc
      },
      { creditsData: [], depositsData: [] },
    )
    const variables = {
      data: {
        date: getFormattedDate(values.paymentDate, SERVER_DATE_FORMAT),
        file: values.file,
        memo: values.memo,
        contractId: contractId,
        creditsData,
        depositsData,
        invoicesData: values.invoices
          .filter((invoice) => selectedInvoicesIds.includes(invoice.id))
          .map((invoice) => ({
            invoiceId: +invoice.id, // should be changed on server side
            discountCents: invoice.appliedDiscountAmountCents,
            paymentCents: invoice.paymentCents + invoice.appliedDiscountAmountCents,
            outstandingCents: invoice.paymentCents + invoice.appliedDiscountAmountCents,
          })),
        paymentMethodId: values.paymentMethod.id,
        appliedCreditCents: paymentSummary.totalAppliedCreditAmountCents,
        appliedDepositCents: paymentSummary.totalAppliedDepositAmountCents,
        paymentCents: paymentSummary.netPaymentCents,
        financialEntityId: values.financialEntityId,
      },
    }
    setPaymentResult(paymentResultMods.LOADING)
    createPayment({
      variables,
    }).then(({ data, errors }) => {
      const responseData = data?.createPayment || {}
      if (responseData?.entity) {
        setPaymentTransactionId(responseData.entity.paymentTransaction?.id)
        setPaymentStatusRefreshCounter((prevState) => prevState + 1)
      } else if (responseData?.errors?.length || errors) {
        setPaymentResult(paymentResultMods.SERVER_ERROR)
      }
    })
  }
  const handleResetClick = () => {
    applySorting('', false)
    gridAPI.current.setRowSelectionModel([])
    formAPI.current.reset(getInitialValues())
  }

  const handleDoneClick = useCallback((isSuccess = true) => {
    setPaymentTransactionId(null)
    setPaymentResult(null)
    setPaymentStatusRefreshCounter(0)
    setFinancialEntitiesOptions([])
    setIsReceivePaymentButtonDisabled(false)
    setFilters({})

    if (isSuccess) {
      client.reFetchObservableQueries()
      setIsOpened(false)
    }
  }, [])
  const handleActionClick = useCallback(() => {
    handleDoneClick(false)
    formAPI.current.restart({})
    refetchInvoices()
    refetchPaymentMethods()
    refetchCreditsDeposits()
    refetchFinancialEntities()
  }, [handleDoneClick, formAPI.current])
  const onAttachmentSubmit = (values) => {
    formAPI.current.mutators.setFile(values.file || void 0)
    setIsAttachmentMemoModalOpened(false)
  }

  return (
    <PageDialog
      bottomPanel={
        <Flex className="w-full ml-16" justifyContent="between">
          <Flex className="gap-x-2">
            <Button
              iconName="plusCircle"
              iconType="outline"
              label={t('addMemo')}
              onClick={() => setIsAddMemoModalOpened(true)}
              size={sizes.SM}
              testData="add-cash-app-memo"
              variant={buttonsVariants.SECONDARY}
            />
            <Button
              iconName="plusCircle"
              iconType="outline"
              label={t('addAttachment')}
              onClick={() => setIsAttachmentMemoModalOpened(true)}
              size={sizes.SM}
              testData="add-payment-memo"
              variant={buttonsVariants.SECONDARY}
            />
          </Flex>
          <Flex className="gap-x-2">
            <Button
              className="w-28"
              label={t('cancel')}
              onClick={() => setIsOpened(false)}
              size={sizes.SM}
              testData="cancel-cash-app"
              variant={buttonsVariants.TERTIARY}
            />
            <Tooltip
              content={
                (getPaymentSummary(formAPI.current?.getState().values).leftToApplyAmountCents !==
                  0 &&
                  t('invalidAppliedAmount')) ||
                (currentUser.erpMetadata?.zeroNetPaymentAllowed &&
                  !selectedInvoicesIds.length &&
                  t('invalidInvoicesNumber'))
              }
              placement="top">
              <Button
                className="w-52"
                disabled={isReceivePaymentButtonDisabled || requestInProgress}
                label={t('applyPayment')}
                onClick={handleSubmit}
                size={sizes.SM}
                testData="submit-cash-app"
                type="submit"
              />
            </Tooltip>
          </Flex>
        </Flex>
      }
      isOpened={isOpened}
      setIsOpened={setIsOpened}
      title={modalTitle}>
      <Form
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
          setFile: (args, state, utils) => {
            utils.changeValue(state, 'file', () => args[0])
          },
          setMemo: (args, state, utils) => {
            utils.changeValue(state, 'memo', () => args[0])
          },
          setPaymentDate: (args, state, utils) => {
            const paymentDate = args[0]
            const paymentMethod = state.formState.values.paymentMethod
            utils.changeValue(state, 'paymentDate', () => paymentDate)
            utils.changeValue(state, 'invoices', (invoices) =>
              getInvoicesWithUpdatedDiscounts({
                invoices,
                paymentMethodType: paymentMethod.type,
                paymentDate,
                isSupportPartialDiscount,
                rollbar,
              }),
            )
          },
          setPaymentMethod: (args, state, utils) => {
            const paymentMethod = args[0]
            const paymentDate = DateTime.now().toJSDate()
            const financialEntityId =
              paymentMethod.type === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD
                ? null
                : financialEntitiesOptions.find((option) => option.isDefault)?.value || null
            utils.changeValue(state, 'paymentMethod', () => paymentMethod)
            utils.changeValue(state, 'financialEntityId', () => financialEntityId)
            utils.changeValue(state, 'paymentDate', () => paymentDate)
            utils.changeValue(state, 'invoices', (invoices) =>
              getInvoicesWithUpdatedDiscounts({
                invoices,
                paymentMethodType: paymentMethod.type,
                paymentDate,
                isSupportPartialDiscount,
                rollbar,
              }),
            )
          },
        }}
        onSubmit={handleSubmit}
        validate={handleValidateForm}>
        {({ form, values, handleSubmit, errors, error }) => {
          formAPI.current = form
          validateNavigationButtonsState(errors)
          const paymentSummary = getPaymentSummary(values)

          return (
            <form className="w-full px-8 py-4 flex gap-16  min-h-[665px]" onSubmit={handleSubmit}>
              <Flex className="min-w-[400px] w-1/5 gap-2" justifyContent="between" column>
                <Box className="flex flex-col gap-3">
                  <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                    {t('enterPaymentDetails')}
                  </Text>
                  <Flex className="w-full gap-2">
                    <Flex className="w-1/2">
                      <Field name="enteredNetPaymentAmountCents">
                        {({ input, meta }) => (
                          <CurrencyInput
                            error={meta.error}
                            label={t('netPaymentAmount')}
                            onChange={(e) => {
                              const value = e.target.value
                              const newValue = Math.round(value * 100)
                              input.onChange(newValue)
                            }}
                            value={input.value / 100}
                          />
                        )}
                      </Field>
                    </Flex>
                    <Flex className="w-1/2">
                      <Field name="paymentDate">
                        {({ input }) => {
                          return (
                            <div>
                              <Text color="text-gray-700" fontWeight={fontWeight.MEDIUM}>
                                {t('paymentDate')}
                              </Text>
                              <DatePicker
                                className="mt-[0.2rem]"
                                disabled={
                                  values.paymentMethod?.type !==
                                  paymentMethodTypes.PAYCHECK_PAYMENT_METHOD
                                }
                                maxDate={DateTime.now().toJSDate()}
                                onChange={form.mutators.setPaymentDate}
                                placeholder={t('chooseDate')}
                                value={input.value}
                              />
                            </div>
                          )
                        }}
                      </Field>
                    </Flex>
                  </Flex>
                  <Field name="paymentMethod">
                    {() => {
                      return (
                        <PaymentType
                          contractCreditCardFeeEnabled={
                            paymentMethodsData?.contract?.paymentSettings?.creditCardFeeEnabled
                          }
                          contractID={contractId}
                          disabled={paymentMethodsLoading}
                          eligibility={paymentMethodsEligibilityEnum.PAYMENT}
                          onCreateMethod={refetchPaymentMethods}
                          selected={values.paymentMethod?.value}
                          setSelected={form.mutators.setPaymentMethod}
                          types={paymentMethods}
                          fullWidth
                        />
                      )
                    }}
                  </Field>
                  <Field name="financialEntityId">
                    {({ input }) => {
                      return (
                        <Flex column>
                          <FinancialEntity
                            disabled={
                              financialEntitiesLoading ||
                              values.paymentMethod?.type !==
                                paymentMethodTypes.PAYCHECK_PAYMENT_METHOD
                            }
                            options={financialEntitiesOptions}
                            selected={input.value}
                            selectedPaymentMethod={values.paymentMethod}
                            setSelected={input.onChange}
                            skipSettingDefaultValue
                            withSingleOptionAutoSelection
                          />
                        </Flex>
                      )
                    }}
                  </Field>
                  <Button
                    disabled={creditsDepositsLoading}
                    label={t('selectCreditsDeposits')}
                    onClick={() => setIsSelectCreditsDepositsModalOpened(true)}
                    variant={buttonsVariants.LINK}
                  />
                </Box>
                <Box>
                  <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                    {t('paymentSummary')}
                  </Text>
                  <DividedList
                    content={getPaymentDescriptionData1(paymentSummary)}
                    labelTextClassName="py-2"
                    labelTextProps={{
                      fontWeight: fontWeight.MEDIUM,
                    }}
                    valueTextClassName="py-2"
                    wrapperClassName="!m-0"
                    skipDivide
                  />
                  <Flex
                    alignItems="center"
                    className="border-t min-h-10 pt-2"
                    justifyContent="between">
                    <Text fontWeight={fontWeight.BOLD}>{t('totalPayment')}</Text>
                    <Money
                      className="font-semibold text-xl"
                      value={paymentSummary.totalPaymentCents}
                    />
                  </Flex>
                  <DividedList
                    content={getPaymentDescriptionData2(paymentSummary)}
                    labelTextClassName="py-2"
                    labelTextProps={{
                      fontWeight: fontWeight.MEDIUM,
                    }}
                    valueTextClassName="py-2"
                    wrapperClassName="!m-0"
                    skipDivide
                  />
                  <Flex
                    alignItems="center"
                    className="border-t min-h-10 pt-2"
                    justifyContent="between">
                    <Text fontWeight={fontWeight.BOLD}>{t('leftToApply')}</Text>
                    <Money
                      className="font-semibold text-xl"
                      value={paymentSummary.leftToApplyAmountCents}
                    />
                  </Flex>
                </Box>
              </Flex>
              <Box className="flex flex-1 flex-col">
                <Text className="pb-4" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                  {t('enterApplicationDetails')}
                </Text>
                <Flex alignItems="start" justifyContent="between">
                  <FiltersControlButton className="pb-4" onApplySearch={setSearch} />
                  <Flex alignItems="end" className="gap-2" column>
                    <Flex className="gap-x-2">
                      <Button
                        disabled={
                          !isReady ||
                          (currentUser.erpMetadata?.zeroNetPaymentAllowed
                            ? false
                            : !paymentSummary.netPaymentCents) ||
                          sort === 'maturity_date.asc'
                        }
                        label={t('oldestToNewest')}
                        onClick={() => applySorting('maturityDate.asc')}
                        size={sizes.SM}
                        variant={buttonsVariants.SECONDARY}
                      />
                      <Button
                        disabled={
                          !isReady ||
                          (currentUser.erpMetadata?.zeroNetPaymentAllowed
                            ? false
                            : !paymentSummary.netPaymentCents) ||
                          sort === 'maturity_date.desc'
                        }
                        label={t('newestToOldest')}
                        onClick={() => applySorting('maturityDate.desc')}
                        size={sizes.SM}
                        variant={buttonsVariants.SECONDARY}
                      />
                    </Flex>
                    <Button
                      className="mt-1"
                      disabled={!isReady}
                      label={t('clearAll')}
                      onClick={handleResetClick}
                      size={sizes.SM}
                      variant={buttonsVariants.LINK}
                    />
                  </Flex>
                </Flex>
                <FieldArray name="invoices">
                  {({ fields }) => (
                    <DataGridComponent
                      availableFilters={availableFilters}
                      columns={getInvoicesColumns(form)}
                      filtersControlledData={{
                        setFilters,
                      }}
                      handleSelectionModelChange={handleSelectionModelChange}
                      handleSortModelChange={handleSortModelChange}
                      isRowSelectable={
                        !currentUser.erpMetadata?.zeroNetPaymentAllowed ||
                        (currentUser.erpMetadata?.zeroNetPaymentAllowed &&
                          paymentSummary.netPaymentCents !== 0)
                          ? ({ row }) =>
                              row.discountAmountCents ||
                              paymentSummary.leftToApplyAmountCents > 0 ||
                              selectedInvoicesIds.includes(row?.id)
                          : void 0
                      }
                      loading={!isReady}
                      page={1}
                      pageSize={MAX_NUMBER}
                      paginationData={paginationData}
                      ref={gridAPI}
                      rowClassName="center-alignment"
                      rowHeight={65}
                      rows={getInvoicesRows(fields)}
                      search={search}
                      searchLabel={t('invoices')}
                      selectionModel={selectedInvoicesIds}
                      sortModel={sortModel}
                      wrapperClassNames="overflow-y-auto mt-2"
                      checkboxSelection
                      hideFooter
                      hideSelectAllCheckbox
                    />
                  )}
                </FieldArray>
                <Flex className="pt-4 text-right" column>
                  {error && error !== hiddenValidationValue && (
                    <div className="text-error" key={error} testData="error">
                      {error}
                    </div>
                  )}
                  {Object.entries(getCombinedErrors(errors.invoices)).map(([key, error]) => (
                    <div className="text-error" key={error} testData={`error-${key}`}>
                      {error}
                    </div>
                  ))}
                </Flex>
              </Box>

              <Dialog
                isOpened={isAddMemoModalOpened}
                setIsOpened={setIsAddMemoModalOpened}
                title={t('addMemo')}>
                <AddMemoForm
                  customerName={contractData?.buyer?.name}
                  memo={values.memo}
                  setIsOpenedModal={setIsAddMemoModalOpened}
                  setMemo={form.mutators.setMemo}
                />
              </Dialog>
              <Dialog
                isOpened={isSelectCreditsDepositsModalOpened}
                setIsOpened={setIsSelectCreditsDepositsModalOpened}
                title={t('selectCreditsDeposits')}
                hideCross>
                <Flex className="w-121 pt-2" column>
                  {paymentSummary.anyCreditsDepositsExists ? (
                    <>
                      <FieldArray name="creditsDeposits" validate={handleValidateCreditsDeposits}>
                        {({ fields }) => (
                          <DataGridComponent
                            columns={getCreditsDepositsColumns(form)}
                            getRowHeight={() => 'auto'}
                            hideFooter={values.creditsDeposits.length <= 6}
                            localPagination={values.creditsDeposits.length > 6}
                            pageSize={5000}
                            rowClassName="center-alignment"
                            rowHeight={65}
                            rows={getCreditsDepositsRows(fields)}
                            wrapperClassNames="header-hidden"
                          />
                        )}
                      </FieldArray>
                      <Flex
                        alignItems="center"
                        className="pt-5 px-3 border-t"
                        justifyContent="between">
                        <Text fontWeight={fontWeight.BOLD}>{t('totalCreditsDepositsApplied')}</Text>
                        <Money
                          className="text-xl font-semibold"
                          value={
                            paymentSummary.totalAppliedCreditAmountCents +
                            paymentSummary.totalAppliedDepositAmountCents
                          }
                        />
                      </Flex>
                      <Text className="pt-2 px-3" color="text-warmBlack-400 block">
                        {t('creditsDepositsSelectedWarn')}
                      </Text>
                      <div className="w-full mt-4 text-right">
                        {Object.entries(getCombinedErrors(errors.creditsDeposits)).map(
                          ([key, error]) => (
                            <div className="text-error" key={error} testData={`error-${key}`}>
                              {error}
                            </div>
                          ),
                        )}
                      </div>
                    </>
                  ) : (
                    <Flex className="py-[140px]" justifyContent="center">
                      <Text
                        color="text-warmBlack-400"
                        fontWeight={fontWeight.MEDIUM}
                        size={sizes.BASE}>
                        {t('noCreditsDepositsAvailable')}
                      </Text>
                    </Flex>
                  )}
                  <Flex justifyContent="end">
                    <Button
                      label={t('ok')}
                      onClick={() => setIsSelectCreditsDepositsModalOpened(false)}
                    />
                  </Flex>
                </Flex>
              </Dialog>
              <Dialog
                isOpened={isAttachmentMemoModalOpened}
                setIsOpened={setIsAttachmentMemoModalOpened}
                title={t('addAttachment')}>
                <UpdateAttachmentForm
                  file={values.file}
                  onSubmit={onAttachmentSubmit}
                  setIsOpenedModal={setIsAttachmentMemoModalOpened}
                />
              </Dialog>
            </form>
          )
        }}
      </Form>
      {paymentResult && (
        <Dialog shouldCloseOnBackdropClick={false} hideCross isOpened>
          <PaymentResult
            failureReason={failureReason}
            mode={paymentResult}
            onActionClick={handleActionClick}
            onDoneClick={handleDoneClick}
            setMode={setPaymentResult}
          />
        </Dialog>
      )}
    </PageDialog>
  )
}

CashApp.propTypes = {
  isOpened: PT.bool.isRequired,
  contractId: PT.string.isRequired,
  setIsOpened: PT.func.isRequired,
  contractData: PT.shape({
    id: PT.string,
    referenceId: PT.string,
    buyer: PT.shape({
      name: PT.string,
    }),
    contractPreference: PT.shape({
      email: PT.bool,
      text: PT.bool,
      phone: PT.bool,
      monthlyStatement: PT.bool,
      specialInstructions: PT.string,
    }),
    availableCreditAmountCents: PT.number,
    creditLimitCents: PT.number,
    status: PT.string,
    slug: PT.string,
    outstandingAmountCents: PT.number,
    overdueLevelGroups: PT.arrayOf(
      PT.shape({
        amountCents: PT.number,
        id: PT.string,
        overdueLevel: PT.shape({
          id: PT.string,
          overdueFrom: PT.number,
          overdueTo: PT.number,
          title: PT.string,
        }),
      }),
    ),
    overdueLevel: PT.shape({
      id: PT.string,
      overdueFrom: PT.number,
      overdueTo: PT.number,
      title: PT.string,
    }),
    netDueAmountCents: PT.number,
    lastCreditReviewDate: PT.string,
  }),
  withPartialPayment: PT.bool,
}

CashApp.defaultProps = {
  contractData: {},
  withPartialPayment: false,
}

export default CashApp
