import React, { useState } from 'react'
import { LoadingSpinner, Text } from '../../ui-kit'
import sizes from '../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import CompanyInformationOverview from './companyInformation/CompanyInformationOverview'
import CompanyInformationEditForm from './companyInformation/CompanyInformationEditForm'
import AsideContainer from '../../components/containers/AsideContainer'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { VendorSettingsQuery } from '../../queries/vendors.gql'
import { companyInformationModes } from './companyInformation/modes'
import ContactInformationEditForm from './companyInformation/ContactInformationEditForm'
import LogoEditSection from './LogoEditSection'
import { useCustomQuery } from '../../hooks/useCustomQuery'

const CompanyInformation = () => {
  const { t } = useTranslation()
  const [mode, setMode] = useState(companyInformationModes.VIEW)
  const currentUser = useCurrentUser()

  const { data, loading } = useCustomQuery({
    query: VendorSettingsQuery,
    queryOptions: {
      variables: { id: currentUser?.vendorId, withUsers: true, withAddresses: true },
    },
    rollbarOptions: { operationName: 'VendorSettingsQuery', target: 'CompanyInformation' },
  })
  const vendorData = data?.vendor || {}
  const renderTitleChild = () => (
    <Text color="text-gray-500" size={sizes.SM}>
      {t('companyInformationDescription')}
    </Text>
  )
  return (
    <AsideContainer title={t('companyInformation')} titleChild={renderTitleChild()}>
      {loading && <LoadingSpinner />}
      {!loading && mode === companyInformationModes.VIEW && (
        <CompanyInformationOverview setMode={setMode} vendor={vendorData} />
      )}
      {!loading && mode === companyInformationModes.EDIT_COMPANY_INFORMATION && (
        <CompanyInformationEditForm setMode={setMode} vendor={vendorData} />
      )}
      {!loading && mode === companyInformationModes.EDIT_CONTACT_INFORMATION && (
        <ContactInformationEditForm setMode={setMode} vendor={vendorData} />
      )}
      {!loading &&
        mode !== companyInformationModes.EDIT_COMPANY_INFORMATION &&
        mode !== companyInformationModes.EDIT_CONTACT_INFORMATION && (
          <LogoEditSection logo={vendorData.logo} vendorId={vendorData.id} />
        )}
    </AsideContainer>
  )
}

export default CompanyInformation
