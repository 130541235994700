import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ConfirmationDialog, Flex, NarrowList, Text, Tooltip } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import AccountTermsModal from '../../../pages/settings/paymentSettingsModals/AccountTermsModal'
import PT from 'prop-types'
import { idType } from '../../../types/defaultTypes'
import { SUPPLI_SUPPORT_EMAIL } from '../../../constants/contacts'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { ContractAutopayConfiguration } from '../../../queries/contracts.gql'

const ContractPaymentSettingsContent = ({
  contractId,
  data,
  loading,
  updatePaymentSettings,
  withPaymentTerms,
  isReadOnlyNonAdmin,
  isUpdatePaymentSettingsLoading,
  disabledOptions,
}) => {
  const [accountTermsModalIsOpened, setAccountTermsModalIsOpened] = useState(false)
  const [confirmationSettingKey, setConfirmationSettingKey] = useState()
  const { paymentSettings } = useCurrentUser()

  const { data: contractAutopayQueryData } = useCustomQuery({
    query: ContractAutopayConfiguration,
    queryOptions: {
      variables: { id: contractId },
      skip: !contractId,
    },
    rollbarOptions: { operationName: 'ContractsAutopayQuery', target: 'AutopayCard' },
  })

  const autopayPaymentMethodType =
    contractAutopayQueryData?.contract?.autopayConfiguration?.paymentMethod?.type

  const handleSubmit = (values, closeFunc) => {
    const variables = {
      id: data.id,
      data: {
        earlyPaymentCreditCardDiscountEnabled: values.earlyPaymentCreditCardDiscountEnabled,
        allowPartialPayments: values.allowPartialPayments,
        creditCardFeeEnabled: values.creditCardFeeEnabled,
        achPaymentForInvoicesEnabled: values.achPaymentForInvoicesEnabled,
        achPaymentForDepositsEnabled: values.achPaymentForDepositsEnabled,
        cardPaymentForInvoicesEnabled: values.cardPaymentForInvoicesEnabled,
        cardPaymentForDepositsEnabled: values.cardPaymentForDepositsEnabled,
      },
    }

    updatePaymentSettings(variables, closeFunc)
  }

  const { t } = useTranslation()
  const handleFieldEnabled = (propName) => {
    const updatedValues = { [propName]: !data[propName], id: data.id }
    handleSubmit(updatedValues)
  }
  const handleSubmitSettingsUpdate = (values, closeFunc) => {
    const updatedValues = { ...data, ...values }
    handleSubmit(updatedValues, closeFunc)
  }

  const editPaymentTerm = () => {
    setAccountTermsModalIsOpened(true)
  }

  const renderItems = () => {
    const settingsList = []

    if (withPaymentTerms) {
      settingsList.push(
        <Flex alignItems="start" className="w-full" key="paymentTerms">
          <div className="w-5/12">
            <Text fontWeight={fontWeight.MEDIUM}>{t('accountTerms')}</Text>
          </div>
          <div className="w-5/12">
            <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
              {data.paymentTerms}
            </Text>
          </div>
          <div className="w-1/12">
            <Button
              className="hidden"
              disabled={isReadOnlyNonAdmin || isUpdatePaymentSettingsLoading}
              iconName={'pencil'}
              label={t('edit')}
              onClick={() => editPaymentTerm()}
              size={sizes.SM}
              testData="edit-payment-term"
              variant={buttonsVariants.LINK}
            />
          </div>
        </Flex>,
      )
    }

    if (paymentSettings.contractAchPaymentSettingsEnabled) {
      settingsList.push(
        <Flex alignItems="start" className="w-full" key="creditCardConvenienceFee">
          <div className="w-5/12">
            <div>
              <Text fontWeight={fontWeight.MEDIUM}>{t('customerPortalAchPayments')}</Text>
            </div>
            <div>
              <Text
                className="pt-2 pr-4"
                color="text-warmBlack-400"
                fontWeight={fontWeight.LIGHT}
                size={sizes.XS}>
                {t('adjustBuyerPaymentsExplanation')}
              </Text>
            </div>
          </div>
          <div className="w-6/12 flex flex-col">
            <Text className="block" fontWeight={fontWeight.MEDIUM}>
              {t('invoicePayments')}
            </Text>
            <Text
              className="pt-1"
              color="text-warmBlack-400"
              fontWeight={fontWeight.LIGHT}
              size={sizes.XS}>
              {t('invoiceAchPaymentsExplanation')}
            </Text>
            <Text className="block pt-4" fontWeight={fontWeight.MEDIUM}>
              {t('depositPayments')}
            </Text>
            <Text
              className="pt-1"
              color="text-warmBlack-400"
              fontWeight={fontWeight.LIGHT}
              size={sizes.XS}>
              {t('depositAchPaymentsExplanation')}
            </Text>
          </div>
          <div className="w-1/12">
            <div>
              <Toggle
                disabled={isReadOnlyNonAdmin || isUpdatePaymentSettingsLoading}
                handleChange={() => {
                  if (
                    autopayPaymentMethodType === 'AchPaymentMethod' &&
                    data.achPaymentForInvoicesEnabled
                  ) {
                    setConfirmationSettingKey('achPaymentForInvoicesEnabled')
                  } else {
                    handleFieldEnabled('achPaymentForInvoicesEnabled')
                  }
                }}
                testData="toggle-allow-partial-payment"
                value={data.achPaymentForInvoicesEnabled}
              />
            </div>
            <div className="mt-[3.2rem]">
              <Toggle
                disabled={isReadOnlyNonAdmin || isUpdatePaymentSettingsLoading}
                handleChange={() => handleFieldEnabled('achPaymentForDepositsEnabled')}
                testData="toggle-allow-partial-payment"
                value={data.achPaymentForDepositsEnabled}
              />
            </div>
          </div>
        </Flex>,
      )
    }
    if (paymentSettings.contractCardPaymentSettingsEnabled) {
      settingsList.push(
        <Flex alignItems="start" className="w-full" key="creditCardConvenienceFee">
          <div className="w-5/12">
            <div>
              <Text fontWeight={fontWeight.MEDIUM}>{t('customerPortalCardPayments')}</Text>
            </div>
            <div>
              <Text
                className="pt-2 pr-4"
                color="text-warmBlack-400"
                fontWeight={fontWeight.LIGHT}
                size={sizes.XS}>
                {t('adjustBuyerPaymentsExplanation')}
              </Text>
            </div>
          </div>
          <div className="w-6/12 flex flex-col">
            <Text className="block" fontWeight={fontWeight.MEDIUM}>
              {t('invoicePayments')}
            </Text>
            <Text
              className="pt-1"
              color="text-warmBlack-400"
              fontWeight={fontWeight.LIGHT}
              size={sizes.XS}>
              {t('invoiceCardPaymentsExplanation')}
            </Text>
            <Text className="block pt-4" fontWeight={fontWeight.MEDIUM}>
              {t('depositPayments')}
            </Text>
            <Text
              className="pt-1"
              color="text-warmBlack-400"
              fontWeight={fontWeight.LIGHT}
              size={sizes.XS}>
              {t('depositCardPaymentsExplanation')}
            </Text>
          </div>
          <div className="w-1/12">
            <div>
              <Toggle
                disabled={isReadOnlyNonAdmin || isUpdatePaymentSettingsLoading}
                handleChange={() => {
                  if (
                    autopayPaymentMethodType === 'CreditCardPaymentMethod' &&
                    data.cardPaymentForInvoicesEnabled
                  ) {
                    setConfirmationSettingKey('cardPaymentForInvoicesEnabled')
                  } else {
                    handleFieldEnabled('cardPaymentForInvoicesEnabled')
                  }
                }}
                testData="toggle-allow-partial-payment"
                value={data.cardPaymentForInvoicesEnabled}
              />
            </div>
            <div className="mt-[3.2rem]">
              <Toggle
                disabled={isReadOnlyNonAdmin || isUpdatePaymentSettingsLoading}
                handleChange={() => handleFieldEnabled('cardPaymentForDepositsEnabled')}
                testData="toggle-allow-partial-payment"
                value={data.cardPaymentForDepositsEnabled}
              />
            </div>
          </div>
        </Flex>,
      )
    }

    if (paymentSettings.creditCardFeeEnabled) {
      settingsList.push(
        <Flex alignItems="start" className="w-full" key="customerPaymentTerms">
          <div className="w-5/12">
            <Text fontWeight={fontWeight.MEDIUM}>{t('creditCardFees')}</Text>
          </div>
          <div className="w-5/12 flex flex-col pt-1">
            <Text size={sizes.XS}>{t('chargeCreditFeeForThisCustomer')}</Text>
          </div>
          <div className="w-1/12" />
          <div className="w-1/12">
            <Tooltip content={isReadOnlyNonAdmin ? t('customerSettingsReadOnlyNonAdmin') : ''}>
              <Toggle
                className="pl-4 pr-5"
                disabled={isReadOnlyNonAdmin}
                handleChange={() => handleFieldEnabled('creditCardFeeEnabled')}
                testData="toggle-allow-partial-payment"
                value={data.creditCardFeeEnabled}
              />
            </Tooltip>
          </div>
        </Flex>,
      )
    }

    settingsList.push(
      <Flex alignItems="start" className="w-full" key="earlyPaymentDiscount">
        <div className="w-5/12">
          <Text fontWeight={fontWeight.MEDIUM}>{t('earlyPaymentDiscountForCardPayments')}</Text>
        </div>
        <div className="w-5/12 flex flex-col pt-1">
          <Text size={sizes.XS}>{t('allowEarlyPaymentDiscount')}</Text>
        </div>
        <div className="w-1/12" />
        <div className="w-1/12">
          <Tooltip
            content={
              isReadOnlyNonAdmin
                ? t('customerSettingsReadOnlyNonAdmin')
                : disabledOptions.includes('earlyPaymentDiscount') &&
                  t('modifySettingsHint', { email: SUPPLI_SUPPORT_EMAIL })
            }>
            <Toggle
              className="pl-4 pr-5"
              disabled={
                isReadOnlyNonAdmin ||
                isUpdatePaymentSettingsLoading ||
                disabledOptions.includes('earlyPaymentDiscount')
              }
              handleChange={() => handleFieldEnabled('earlyPaymentCreditCardDiscountEnabled')}
              testData="toggle-early-payment-discount"
              value={data.earlyPaymentCreditCardDiscountEnabled}
            />
          </Tooltip>
        </div>
      </Flex>,
    )

    settingsList.push(
      <Flex alignItems="start" className="w-full" key="customerPaymentTerms">
        <div className="w-5/12">
          <Text fontWeight={fontWeight.MEDIUM}>{t('partialPayments')}</Text>
        </div>
        <div className="w-5/12 flex flex-col pt-1">
          <Text size={sizes.XS}>{t('allowPartialPaymentsInTheCustomerPortal')}</Text>
        </div>
        <div className="w-1/12" />
        <div className="w-1/12">
          <Tooltip
            content={
              isReadOnlyNonAdmin
                ? t('customerSettingsReadOnlyNonAdmin')
                : disabledOptions.includes('allowPartialPayments') &&
                  t('modifySettingsHint', { email: SUPPLI_SUPPORT_EMAIL })
            }>
            <Toggle
              className="pl-4 pr-5"
              disabled={
                isReadOnlyNonAdmin ||
                isUpdatePaymentSettingsLoading ||
                disabledOptions.includes('allowPartialPayments')
              }
              handleChange={() => handleFieldEnabled('allowPartialPayments')}
              testData="toggle-allow-partial-payment"
              value={data.allowPartialPayments}
            />
          </Tooltip>
        </div>
      </Flex>,
    )

    return settingsList
  }

  return (
    <>
      {!loading && (
        <div className="mr-10" onSubmit={handleSubmit}>
          <NarrowList listItems={renderItems()} />
        </div>
      )}

      {!isReadOnlyNonAdmin && accountTermsModalIsOpened && (
        <AccountTermsModal
          handleSubmit={(values) => {
            handleSubmitSettingsUpdate(values, setAccountTermsModalIsOpened)
          }}
          initialAccountTerms={data.paymentTerms}
          isOpened={accountTermsModalIsOpened}
          setIsOpened={setAccountTermsModalIsOpened}
        />
      )}
      <ConfirmationDialog
        isOpened={confirmationSettingKey}
        onSubmitButtonNoClick={() => {
          setConfirmationSettingKey(null)
        }}
        onSubmitButtonYesClick={() => {
          handleFieldEnabled(confirmationSettingKey)
          setConfirmationSettingKey(null)
        }}
        setIsOpened={() => {
          setConfirmationSettingKey(null)
        }}
        submitButtonNoLabel={t('cancel')}
        submitButtonYesLabel={t('confirm')}
        title={t('confirmChanges')}>
        {confirmationSettingKey === 'achPaymentForInvoicesEnabled' && (
          <Flex className="w-[400px]" column>
            <Text className="pt-8" color="text-black-500">
              {t('achPaymentsWillCancelAutopayments')}
            </Text>
          </Flex>
        )}
        {confirmationSettingKey === 'cardPaymentForInvoicesEnabled' && (
          <Flex className="w-[400px]" column>
            <Text className="pt-8" color="text-black-500">
              {t('cardPaymentsWillCancelAutopayments')}
            </Text>
          </Flex>
        )}
      </ConfirmationDialog>
    </>
  )
}

export default ContractPaymentSettingsContent
const contractPaymentSettings = PT.shape({
  earlyPaymentCreditCardDiscountEnabled: PT.bool.isRequired,
  id: idType,
  paymentTerms: PT.number.isRequired,
  allowPartialPayments: PT.bool,
  creditCardFeeEnabled: PT.bool,
  achPaymentForInvoicesEnabled: PT.bool,
  achPaymentForDepositsEnabled: PT.bool,
  cardPaymentForInvoicesEnabled: PT.bool,
  cardPaymentForDepositsEnabled: PT.bool,
})

ContractPaymentSettingsContent.propTypes = {
  contractId: PT.string,
  data: PT.oneOfType([contractPaymentSettings]),
  loading: PT.bool.isRequired,
  updatePaymentSettings: PT.func.isRequired,
  withLienWaiver: PT.bool,
  withCreditLimit: PT.bool,
  withPaymentTerms: PT.bool,
  isReadOnlyNonAdmin: PT.bool,
  isUpdatePaymentSettingsLoading: PT.bool,
  disabledOptions: PT.arrayOf(PT.string),
}

ContractPaymentSettingsContent.defaultProps = {
  disabledOptions: [],
  isReadOnlyNonAdmin: false,
  withLienWaiver: false,
  withCreditLimit: false,
  withPaymentTerms: false,
  isUpdatePaymentSettingsLoading: false,
}
