import PT from 'prop-types'
import { DEFAULT_DATE_FORMAT, getFormattedDate } from '../../utils/dateUtils'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

const FormattedDate = ({ date, format, timeZone }) => {
  const currentUser = useCurrentUser()
  return date ? getFormattedDate(date, format, timeZone ? timeZone : currentUser?.timeZone) : '-'
}

FormattedDate.propTypes = {
  date: PT.string,
  format: PT.string,
}

FormattedDate.defaultProps = {
  date: null,
  format: DEFAULT_DATE_FORMAT,
}

export default FormattedDate
